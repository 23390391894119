.uexpert {
	color: $c5;
	position: relative;
	text-align: center;

	&_img {
		z-index: 1;
		width: 114px;
		height: 114px;
		position: relative;
		border-radius: 50%;
		margin: 0px auto -57px;
		background: #fff no-repeat;
		box-shadow: 0px 10px 16px rgba(#8c8c8c, .48);
		background-size: cover;
	}
	&_info {
		background: #fff;
		border-radius: 5px;
		padding: 74px 20px 35px;
		font: _font(16, 20, $f1);
		border-bottom: 5px solid $c1;

		@mixin screen_to, $mobile {
			font: _font(14, 18, $f1);
		}

		ul {
			margin-top: 21px;

			@mixin screen_to, $mobile {
				margin-top: 17px;
			}
		}
		li {
			margin: 0px;

			&:before {
				content: normal;
			}
		}
	}
	&_name {
		color: #000;
		display: block;
		font: 18px/1 $f1;
	}
	&_post {
		display: block;
		margin-top: 3px;

		@mixin screen_to, $mobile {
			margin-top: 2px;
		}
	}

	& + & {
		margin-top: 30px;
	}
}