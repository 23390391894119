.services {
	max-width: 988px;
	margin: -20px auto 0px;

	@mixin screen_to, $mobile {
		margin-top: -10px;
		max-width: $mwmbl;
	}

	&_box {
		display: flex;
		flex-flow: wrap;
		padding: 0px 10px;
	}
	&_item {
		background: #fff;
		position: relative;
		margin: 20px 10px 0px;
		width: calc(50% - 20px);
		padding: 31px 40px 112px;
		border-bottom: 6px solid $cb1;
		box-shadow: 0px 6px 18px rgba(#000, .05);

		@mixin screen_to, $mobile {
			width: 100%;
			margin: 10px 0px 0px;
			padding: 23px 16px 80px;
		}

		&_btn {
			left: 40px;
			bottom: 40px;
			position: absolute;

			@mixin screen_to, $mobile {
				left: 16px;
				bottom: 16px;
			}
		}
		&_name {
			color: $c1;
			border: none;
			vertical-align: top;
			background: $gSelection;
			font: _font(30, 36, $f1b);
			transition: all .4s ease-in-out-sine;
			background-size: 200%;

			@mixin screen_to, $mobile {
				background: none;
				font: 18px/1 $f1b;
				vertical-align: top;
				display: inline-block;
			}

			&:hover {
				color: #000;
				background-position: -100% 0%;
			}
		}
		&_text {
			margin-top: 17px;

			@mixin screen_to, $mobile {
				margin-top: 12px;
			}
		}
		&_link {
			right: 20px;
			bottom: 54px;
			position: absolute;

			@mixin screen_to, $mobile {
				right: 16px;
				bottom: 30px;
			}
		}

		&._2 {
			border-color: $cb2;
		}
		&._3 {
			border-color: $cb3;
		}
		&._4 {
			border-color: $cb4;
		}
	}
	&_news {
		z-index: 1;
		border: none;
		color: #000;
		display: block;
		background: #fff;
		position: relative;
		margin: -6px 10px 0px;
		padding: 0px 40px 51px;
		width: calc(50% - 20px);
		border-bottom: 6px solid $cb1;
		box-shadow: 0px 6px 18px rgba(#000, .05);

		@mixin screen_to, $mobile {
			display: none;
		}

		&:before {
			height: 1px;
			content: '';
			display: block;
			background: $c3;
			margin-bottom: 23px;
		}

		&_date {
			color: $c4;
			display: block;
			font: 14px/1 $f1;
			text-align: right;
			margin-bottom: 3px;
		}
		&_name {
			color: $c1;
			vertical-align: top;
			background: $gSelection;
			font: _font(22, 26, $f1b);
			transition: all .4s ease-in-out-sine;
			background-size: 200%;
		}
		&_text {
			margin-top: 19px;
		}
		&_link {
			right: 20px;
			bottom: 30px;
			position: absolute;
		}

		&._1 {
			border-color: $cb1;
		}
		&._2 {
			border-color: $cb2;
		}
		&._3 {
			border-color: $cb3;
		}
		&._4 {
			border-color: $cb4;
		}
		&:hover {
			color: #000;

			^&_name {
				color: #000;
				background-position: -100% 0%;
			}
		}
	}
}