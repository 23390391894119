.sphoto {
	font: 0/0 serif;
	overflow: hidden;
	margin: 60px auto;

	@mixin screen_to, $mobile {
		margin: 40px auto;
	}

	&_show {
		z-index: 10;
		position: relative;

		&_item {
			width: 100%;
			opacity: .6;
			max-width: 754px;
			min-height: 100px;
			background: $c3 50% no-repeat;
			transition: opacity .4s ease-out-sine;
			background-size: auto 100%;

			@mixin screen_to, $mobile {
				width: calc(100% - 60px);
			}

			&:after {
				content: '';
				display: block;
				padding-bottom: calc(100% / (754 / 400));
			}

			&.swiper-slide-active {
				opacity: 1;
			}
		}
	}
	&_info {
		max-width: 600px;
		margin: 16px auto 0px;

		@mixin screen_to, $mobile {
			padding: 0px 10px;
			max-width: $mwmbl;
		}

		&:before {
			height: 1px;
			content: '';
			display: block;
			background: $c3;
			margin: 0px auto 12px;

			@mixin screen_to, $mobile {
				margin-bottom: 10px;
				width: calc(100% - 40px);
			}
		}

		&_item {
			color: $c5;
			width: 100%;
			padding: 0px 20px;
			text-align: center;
			font: _font(14, 18, $f1);

			@mixin screen_to, $mobile {
				font: _font(12, 16, $f1);
			}
		}
	}
	&_dots {
		margin-top: 16px;
		text-align: center;

		@mixin screen_to, $mobile {
			margin-top: 15px;
		}
	}
	&_prev,
	&_next {
		top: 50%;
		color: $c3;
		z-index: 15;
		width: 60px;
		height: 20px;
		overflow: hidden;
		margin-top: -10px;
		font: 20px/1 $ico;
		position: absolute;
		transition: color .2s ease-out-sine;

		@mixin screen_to, $mobile {
			width: 26px;
			font-size: 10px;
		}

		&:after {
			top: 0px;
			display: block;
			position: absolute;
			transition: transform .4s ease-out-sine;

			@mixin screen_to, $mobile {
				top: 5px;
			}
		}

		&:hover {
			color: $c1;

			@mixin screen_to, $mobile {
				color: $c3;
			}

			&:after {
				transform: none;
			}
		}
	}
	&_prev {
		left: 100px;

		@mixin screen_to, $mobile {
			left: 10px;
		}

		&:after {
			left: 0px;
			content: $ico_arrow_l1;
			transform: translateX(20px);

			@mixin screen_to, $mobile {
				transform: none;
			}
		}
	}
	&_next {
		right: 100px;

		@mixin screen_to, $mobile {
			right: 10px;
		}

		&:after {
			right: 0px;
			content: $ico_arrow_r1;
			transform: translateX(-20px);

			@mixin screen_to, $mobile {
				transform: none;
			}
		}
	}

	&:last-child {
		margin-bottom: 0px;
	}
	&:first-child {
		margin-top: 0px;
	}
}