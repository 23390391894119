.jobs {
	max-width: 988px;
	margin: 0px auto;

	@mixin screen_to, $mobile {
		max-width: $mwmbl;
	}

	&_box {
		display: flex;
		flex-flow: wrap;
		padding: 0px 10px;
	}
	&_item {
		color: #000;
		background: #fff;
		position: relative;
		margin: 20px 10px 0px;
		padding: 33px 40px 52px;
		width: calc(50% - 20px);
		border-bottom: 6px solid $cb1;
		box-shadow: 0px 6px 18px rgba(#000, .05);

		@mixin screen_to, $mobile {
			width: 100%;
			margin: 10px 0px 0px;
			padding: 23px 16px 40px;
		}

		&_name {
			color: $c1;
			vertical-align: top;
			background: $gSelection;
			font: _font(22, 26, $f1b);
			transition: all .4s ease-out-sine;
			background-size: 200%;

			@mixin screen_to, $mobile {
				background: none;
				font: 18px/1 $f1b;
				vertical-align: top;
				display: inline-block;
			}
		}
		&_firm {
			display: block;
			margin-top: 9px;

			@mixin screen_to, $mobile {
				margin-top: 12px;
			}
		}
		&_link {
			right: 20px;
			bottom: 30px;
			position: absolute;

			@mixin screen_to, $mobile {
				right: 16px;
				bottom: 16px;
			}
		}

		&:hover {
			color: #000;
			border-color: $cb1;

			^&_name {
				color: #000;
				background-position: -100% 0%;
			}
		}
		&:nth-child(2n) {
			border-color: $cb2;
		}
		&:nth-child(4n) {
			border-color: $cb4;
		}
		&:nth-child(4n-1) {
			border-color: $cb3;
		}
	}
}