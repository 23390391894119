#preloader {
	top: 0px;
	right: 0px;
	width: 100%;
	height: 100%;
	z-index: 3000;
	display: none;
	position: fixed;
	background: rgba(#020204, .5);

	&:after,
	&:before {
		top: 50%;
		left: 50%;
		content: '';
		display: block;
		position: absolute;
	}
	&:after {
		width: 98px;
		height: 88px;
		color: #fff;
		font: 88px/1 $ico;
		content: $ico_logo;
		margin: -44px 0px 0px -49px;
		animation: preloader .8s ease-in-out infinite alternate both;
	}
	&:before {
		width: 180px;
		height: 180px;
		border-radius: 50%;
		border: 2px solid #fff;
		margin: -90px 0px 0px -90px;
		border-top-color: transparent;
		border-bottom-color: transparent;
		animation: spin 1s linear infinite both;
	}

	&._show {
		top: 0px;
		right: 0px;
		opacity: 1;
		visibility: visible;
	}
}

@keyframes preloader {
	100% {
		transform: scale(1.1);
	}
}