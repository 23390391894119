.radio {
	width: 20px;
	height: 20px;
	cursor: pointer;
	position: relative;
	border-radius: 50%;
	vertical-align: top;
	background: #f5f6f7;
	display: inline-block;
	border: 1px solid #f5f6f7;
	transition: all .4s ease-out-sine;

	&:after {
		top: 50%;
		left: 50%;
		opacity: 0;
		content: '';
		width: 10px;
		height: 10px;
		display: block;
		border-radius: 50%;
		position: absolute;
		transform: scale(0);
		background: #a6a5a6;
		margin: -5px 0px 0px -5px;
		transition: all .2s ease-out-sine;
	}

	&._error {
		border-color: $cerror;
	}
	&._checked {
		&:after {
			opacity: 1;
			transform: none;
		}
	}
	&:hover
	label:hover & {
		border-color: $c1;
	}
}