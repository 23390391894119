.fjobs {
	font: 0/0 serif;
	max-width: 988px;
	margin: 0px auto;
	padding: 0px 65px;
	position: relative;

	@mixin screen_to, $mobile {
		padding: 0px 10px;
		max-width: $mwmbl;
	}

	&:before {
		left: 25px;
		color: $c5;
		bottom: 10px;
		font: 20px/1 $ico;
		position: absolute;
		content: $ico_filter;
	}

	&_cell {
		vertical-align: top;
		display: inline-block;
		width: calc(33.3334% - 20px);

		@mixin screen_to, $mobile {
			width: calc(50% - 5px);
		}

		& + & {
			margin-left: 10px;
		}
	}
	&_label {
		color: $c5;
		display: block;
		font: 14px/1 $f1;
	}
	&_select {
		margin-top: 7px;
		border-color: #fff;
		box-shadow: 0px 6px 18px rgba(#000, .05);
	}

	/*#region [plugin][:]*/
	.sod {
		&_list {
			&_wrapper {
				border-color: #fff;
				box-shadow: 0px 6px 18px rgba(#000, .05);
			}
		}
		&_select {
			&.above {
				.sod {
					&_list {
						&_wrapper {
							box-shadow: 0px -6px 18px rgba(#000, .05);
						}
					}
				}
			}
		}
	}
	/*#endregion [plugin][;]*/
}