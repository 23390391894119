.input,
textarea {
	width: 100%;
	margin: 0px;
	resize: none;
	color: #000;
	outline: none;
	display: block;
	overflow: hidden;
	border-radius: 5px;
	background: #f5f6f7;
	font: _font(16, 18, $f1);
	border: 2px solid #f5f6f7;
	transition: color .4s ease-out-sine,
				border .4s ease-out-sine;

	@mixin screen_to, $mobile {
		font-size: 14px;
	}

	&::placeholder {
		opacity: 1;
		color: $chint;
		transition: all .2s ease-out-sine;
	}

	&:hover,
	&:focus {
		border-color: $c1;
	}
	&:focus {
		&::placeholder {
			color: transparent !important;
		}
	}
	&._error {
		border-color: $cerror;
	}
	&:disabled {
		opacity: .6;
		border-color: #f5f6f7;
	}
	&[readonly] {
		border-color: #f5f6f7;
	}
}
.input {
	height: 40px;
	padding: 0px 15px;
	
	@mixin screen_to, $mobile {
		padding: 0px 10px;
	}
}
textarea {
	height: 120px;
	padding: 10px 15px;
	
	@mixin screen_to, $mobile {
		padding: 10px;
	}
}