.news {
	max-width: 988px;
	margin: -20px auto 0px;

	@mixin screen_to, $mobile {
		margin-top: -10px;
		max-width: $mwmbl;
	}

	&_box {
		display: flex;
		flex-flow: wrap;
		padding: 0px 10px;
	}
	&_item {
		background: #fff;
		position: relative;
		margin: 20px 10px 0px;
		padding: 0px 40px 52px;
		width: calc(50% - 20px);
		border-bottom: 6px solid $cb1;
		box-shadow: 0px 6px 18px rgba(#000, .05);

		@mixin screen_to, $mobile {
			width: 100%;
			margin: 10px 0px 0px;
			padding: 0px 16px 40px;
		}

		&_tag {
			color: $c5;
			border: none;
			height: 36px;
			padding: 0px 15px;
			font: 14px/36px $f1;
			vertical-align: top;
			background: #eaeaea;
			display: inline-block;
			transition: all .4s ease-out-sine;

			@mixin screen_to, $mobile {
				height: 30px;
				font: 12px/30px $f1;
			}

			&:hover {
				color: #fff;
				background: $c1;
			}
		}
		&_date {
			color: $c4;
			display: block;
			margin-top: 1px;
			font: 14px/1 $f1;
			text-align: right;

			@mixin screen_to, $mobile {
				font-size: 12px;
				margin-top: 4px;
			}

			&:first-child {
				margin-top: 37px;

				@mixin screen_to, $mobile {
					margin-top: 24px;
				}
			}
		}
		&_head {
			position: relative;
			margin-bottom: 3px;

			@mixin screen_to, $mobile {
				margin: 0px;
			}

			&:before {
				width: 100%;
				content: '';
				display: table;
			}
		}
		&_name {
			border: none;
			color: #000;
			vertical-align: top;
			background: $gSelection;
			font: _font(30, 36, $f1);
			transition: all .4s ease-in-out-sine;
			background-size: 200%;

			@mixin screen_to, $mobile {
				background: none;
				font: 24px/1 $f1;
				vertical-align: top;
				display: inline-block;
			}

			&._bold {
				font-family: $f1b;
			}
			&:hover {
				color: #000;
				background-position: -100% 0%;
			}
		}
		&_text {
			margin-top: 16px;

			@mixin screen_to, $mobile {
				margin-top: 11px;
			}
		}
		&_link {
			right: 20px;
			bottom: 30px;
			position: absolute;

			@mixin screen_to, $mobile {
				right: 16px;
				bottom: 16px;
			}
		}

		&:nth-child(2n) {
			border-color: $cb2;
		}
		&:nth-child(4n) {
			border-color: $cb4;
		}
		&:nth-child(4n-1) {
			border-color: $cb3;
		}
	}
}