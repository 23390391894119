.lbar {
	max-width: 1134px;
	text-align: right;
	padding: 0px 20px;
	margin: 20px auto -30px;

	@mixin screen_to, $mobile {
		padding: 0px 10px;
		max-width: $mwmbl;
		text-align: center;
	}
}