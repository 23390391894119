p,
ol,
ul {
	margin: calc($p_mn - $p_cl) 0px calc($p_mn - $p_bl);

	@mixin screen_to, $mobile {
		margin: calc($p_mn_m - $p_cl_m) 0px calc($p_mn_m - $p_bl_m);
	}

	& + p,
	& + ol,
	& + ul {
		margin-top: calc($p_cl * -1);

		@mixin screen_to, $mobile {
			margin-top: calc($p_cl_m * -1);
		}
	}

	& + h2, & + .h2 {
		margin-top: calc($h2_mt - ($p_bl + $h2_cl));

		@mixin screen_to, $mobile {
			margin-top: calc($h2_mt_m - ($p_bl_m + $h2_cl_m));
		}
	}
	& + h3, & + .h3 {
		margin-top: calc($h3_mt - ($p_bl + $h3_cl));

		@mixin screen_to, $mobile {
			margin-top: calc($h3_mt_m - ($p_bl_m + $h3_cl_m));
		}
	}
	& + h4, & + .h4 {
		margin-top: calc($h4_mt - ($p_bl + $h4_cl));

		@mixin screen_to, $mobile {
			margin-top: calc($h4_mt_m - ($p_bl_m + $h4_cl_m));
		}
	}
	& + h5, & + .h5 {
		margin-top: calc($h5_mt - ($p_bl + $h5_cl));

		@mixin screen_to, $mobile {
			margin-top: calc($h5_mt_m - ($p_bl_m + $h5_cl_m));
		}
	}
	& + h6, & + .h6 {
		margin-top: calc($h6_mt - ($p_bl + $h6_cl));

		@mixin screen_to, $mobile {
			margin-top: calc($h6_mt_m - ($p_bl_m + $h6_cl_m));
		}
	}

	&:last-child {
		margin-bottom: 0px;
	}
	&:first-child {
		margin-top: 0px;
	}
}