.lbeep {
	padding: 0px 30px;
	margin-top: -33px;
	position: relative;

	@mixin screen_to, $mobile {
		padding: 0px;
		text-align: center;
	}

	&_btn {
		color: $c1;
		opacity: 0;
		width: 340px;
		height: 80px;
		border-radius: 40px;
		border: 2px dotted $c1;
		padding: 12px 30px 0px;
		transition: all .4s ease-out-sine;

		@mixin screen_to, $mobile {
			opacity: 1;
			margin: 0px auto;
			padding: 20px 20px 0px;
		}

		&_txt {
			display: block;

			&._1 {
				font: 30px/1 $f1b;

				@mixin screen_to, $mobile {
					font-size: 18px;
				}
			}
			&._2 {
				margin-top: 3px;
				font: 18px/1 $f1b;

				@mixin screen_to, $mobile {
					font-size: 14px;
					margin-top: 2px;
				}
			}
		}

		&._ok {
			float: right;
			transform: translate(-40px, -20%);

			@mixin screen_to, $mobile {
				float: none;
				transform: none;
			}
		}
		&._no {
			float: left;
			border-color: $c4;
			transform: translate(40px, -20%);

			@mixin screen_to, $mobile {
				display: none;
			}
		}
		&._hide {
			opacity: 0;
			visibility: hidden;
			transform: scale(0);
			pointer-events: none;
		}
		&:hover {
			color: #fff;
			background: $c1;
			border-color: $c1;
			box-shadow: 0px 2px 8px rgba($c2, .4);
		}
	}
	&_note {
		color: $c4;
		display: none;
		font: 14px/1 $f1;
		margin-bottom: 14px;

		@mixin screen_to, $mobile {
			display: block;
		}
	}
	&_list {
		margin: 0px;
		font: 26px/1 $f1;

		a {
			color: $c4;
			border-width: 2px;
			vertical-align: top;
			display: inline-block;

			&:hover {
				color: $c1;
			}
		}
		li {
			color: #000;

			&:after {
				color: $c4;
				font-size: 14px;
				margin-left: 33px;
				display: inline-block;
				content: attr(data-note);
				vertical-align: baseline;

				@mixin screen_to, $mobile {
					content: normal;
				}
			}
			&:before {
				content: normal;
			}

			& + li {
				margin-top: 5px;
			}
		}

		&-phns {
			@mixin screen_to, $mobile {
				font-size: 24px;
			}

			a {
				border: none;
				color: #000;
			}
		}
		&-mail {
			margin-top: 22px;

			@mixin screen_to, $mobile {
				font-size: 18px;
				margin-top: 24px;
			}
		}
	}
	&_data {
		margin: 0px 0px 36px 307px;

		@mixin screen_to, $mobile {
			margin: 0px;
			padding-top: 30px;
		}
	}
	&_foot {
		margin: 0px auto;
		max-width: 780px;
		justify-content: space-between;
		transition: all .4s ease-out-sine;

		@mixin screen_to, $mobile {
			margin-top: 20px;
		}

		&:after {
			width: 100%;
			content: '';
			display: table;
		}

		&._one {
			max-width: 340px;
		}
	}

	.aos-animate & {
		^&_btn {
			opacity: 1;
			transform: none;
		}
	}
}