.wjob {
	display: flex;
	flex-flow: wrap;
	padding: 0px 20px;
	max-width: 1084px;
	margin: 50px auto 0px;

	@mixin screen_to, $mobile {
		padding: 0px;
		max-width: $mwmbl;
	}

	/*#region [scheme][:]*/
	&_base {
		padding: 0px 40px;
		width: calc(100% - 288px);

		@mixin screen_to, $mobile {
			width: auto;
			padding: 0px 15px;
		}
	}
	&_side {
		width: 100%;
		max-width: 268px;
		padding-top: 9px;
		margin-left: 20px;
		text-align: center;

		@mixin screen_to, $mobile {
			max-width: 320px;
			padding: 0px 10px;
			margin: 36px auto 0px;
		}

		&_title {
			color: $c4;
			display: block;
			padding: 0px 20px;
			text-align: center;
			margin: 0px auto 26px;
			font: _font(18, 22, $f2i);

			@mixin screen_to, $mobile {
				font-size: 14px;
				padding: 0px 15px;
				margin-bottom: 16px;
			}
		}
	}
	&_info {
		margin: 0px -40px;
		background: #fff;
		border-radius: 10px;
		padding: calc(40px - $h4_cl) 40px 40px;
		box-shadow: 0px 6px 18px rgba(#000, .05);

		@mixin screen_to, $mobile {
			box-shadow: none;
			margin: 0px -15px;
			border-radius: 0px;
			padding: calc(30px - $h4_cl_m) 15px 30px;
		}

		&:before {
			width: 100%;
			content: '';
			display: table;
		}

		& > :last-child {
			margin-bottom: 0px;
		}
		& > :first-child {
			margin-top: 0px;
		}
	}
	&_foot {
		margin-top: 34px;
		overflow: hidden;
		padding: 30px 0px 0px;
		border-top: 1px solid #ccc;
		
		@mixin screen_to, $mobile {
			margin-top: 26px;
			text-align: center;
		}
	}
	/*#endregion [scheme][;]*/

	/*#region [object][:]*/
	&_btn {
		&._blank {
			color: $c2;
			font: 14px/1 $f1b;
			margin: 17px 0px 0px 20px;
			text-transform: uppercase;

			@mixin screen_to, $mobile {
				display: block;
				margin: 17px 0px 0px;
			}
		}
	}
	&_more {
		margin-top: 40px;

		@mixin screen_to, $mobile {
			margin-top: 30px;
		}
	}
	&_firm {
		color: $c5;
		font-family: $f1b;
		margin: 14px 0px 35px;

		@mixin screen_to, $mobile {
			margin: 9px 0px 18px;
		}
	}
	&_title {
		margin-bottom: $h4_mb;

		@mixin screen_to, $mobile {
			margin-bottom: $h4_mb_m;
		}

		span {
			vertical-align: top;
			display: inline-block;
			border-bottom: 6px solid $cb1;
		}

		&:nth-of-type(2n) {
			span {
				border-color: $cb2;
			}
		}
		&:nth-of-type(4n) {
			span {
				border-color: $cb4;
			}
		}
		&:nth-of-type(4n-1) {
			span {
				border-color: $cb3;
			}
		}
	}
	&_share {
		float: right;
	
		@mixin screen_to, $mobile {
			float: none;
			margin-top: 18px;
		}
	}
	/*#endregion [object][;]*/

}