.lcodex {
	display: flex;
	flex-flow: wrap;
	text-align: left;
	margin-bottom: 25px;

	@mixin screen_to, $mobile {
		display: block;
		margin-bottom: 26px;
	}

	/*#region [scheme][:]*/
	&_data {
		flex-grow: 1;
		flex-shrink: 0;
		max-width: 560px;
		margin-right: 20px;

		@mixin screen_to, $mobile {
			margin: 0px;
		}

		ul {
			padding-left: 20px;
			
			@mixin screen_to, $mobile {
				padding-left: 5px;
			}
		}
		li {
			position: relative;
			padding-left: 28px;
			
			@mixin screen_to, $mobile {
				padding-left: 26px;
			}
	
			&:before {
				content: normal;
			}
	
			& + li {
				margin-top: 20px;

				@mixin screen_to, $mobile {
					margin-top: 21px;
				}
			}
		}
	}
	&_side {
		flex-grow: 1;
		flex-basis: 0;
		display: flex;
		flex-flow: wrap;
		align-items: center;
	}
	/*#endregion [scheme][;]*/

	/*#region [object][:]*/
	&_pin {
		left: 0px;
		color: $c3;
		width: 12px;
		height: 12px;
		top: em(5, 18);
		border-radius: 50%;
		position: absolute;
		background: currentColor;
		transition: color .8s ease-in-out-sine .1s;

		@mixin screen_to, $mobile {
			top: 6px;
			width: 6px;
			height: 6px;
		}
	}
	&_title {
		margin: 0px 0px 23px;
		font: _font(24, 30, $f1);
		
		@mixin screen_to, $mobile {
			margin-bottom: 25px;
			font: _font(16, 20, $f1);
		}
	}
	/*#endregion [object][;]*/

}