.ajax {
	&_spin {
		font: 0/0 serif;
		margin-top: 40px;
		text-align: center;

		&_itm {
			margin: 0px 10px;
		}
		&_itm,
		&:after,
		&:before {
			content: '';
			width: 10px;
			height: 10px;
			background: #fff;
			border-radius: 50%;
			vertical-align: top;
			display: inline-block;
			box-shadow: 0px 3px 6px rgba(#000, .05);
			animation: ball-beat .8s ease-out-sine infinite both;
		}
		&:after,
		&:before {
			animation-delay: -0.35s;
		}
	}
}