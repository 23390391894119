.utease {
	color: $c1;
	z-index: 1;
	border: none;
	display: flex;
	flex-flow: wrap;
	overflow: hidden;
	background: #fff;
	position: relative;
	border-radius: 10px;
	margin: 20px 20px 0px;
	box-shadow: 0px 6px 18px rgba(#000, .05);

	@mixin screen_to, $mobile {
		margin: 10px 10px 0px;
	}

	&_img {
		flex-grow: 1;
		background-size: cover;
		background-repeat: no-repeat;
		
		&:after {
			height: 0px;
			content: '';
			display: block;
			padding-bottom: calc(100% / (270 / 120));
		}
	}
	&_txt {
		width: 100%;
		display: block;
		margin: 0px auto;
		max-width: 558px;
	}
	&_data {
		display: flex;
		padding: 0px 20px;
		flex-flow: column;
		justify-content: center;
		font: _font(22, 26, $f1);
		width: calc(100% / (948 / 678));

		@mixin screen_to, $mobile {
			padding: 20px;
			font-size: 18px;
		}
	}

	&:hover {
		color: $c6;
	}
}