.lplane {
	z-index: 800;
	margin: 0px auto;
	max-width: 1600px;
	position: relative;

	&_item {
		top: 100%;
		opacity: 0;
		left: -122px;
		width: 244px;
		height: 273px;
		position: absolute;
		pointer-events: none;
		transform: translateZ(0);
		transform-origin: 118px 100%;
		will-change: opacity, transform;

		&:after,
		&:before {
			top: 0px;
			left: 0px;
			content: '';
			width: 100%;
			height: 100%;
			display: block;
			position: absolute;
			pointer-events: none;
			background: no-repeat;
			background-size: contain;
			transition: opacity .4s ease-out-sine;
		}
		&:after {
			opacity: 0;
			background-image: url('/assets/img/design/clipart/plane_432x482_v1s2.png');
		}
		&:before {
			background-image: url('/assets/img/design/clipart/plane_432x482_v1s1.png');
		}

		&._st1 {
			opacity: 1;
			transition: all .6s ease-out-sine;
			transform: translate3d(90%, -100%, 0);
		}
		&._st2 {
			&:after {
				opacity: 1;
				transition-delay: 0s;
			}
			&:before {
				opacity: 0;
				transition-delay: .16s;
			}
		}
		&._st3 {
			transition: transform 1.2s ease-in-out-quad;
		}
	}
}