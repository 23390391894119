.team {
	display: flex;
	flex-flow: wrap;
	font: 0/0 serif;
	max-width: 988px;
	margin: 0px auto;
	padding: 0px 10px;

	@mixin screen_to, $mobile {
		display: block;
		max-width: $mwmbl;
	}

	&_item {
		display: flex;
		flex-flow: wrap;
		background: #fff;
		position: relative;
		margin: 20px 10px 0px;
		padding: 0px 40px 30px;
		width: calc(50% - 20px);
		border-bottom: 6px solid $cb1;
		box-shadow: 0px 6px 18px rgba(#000, .05);

		@mixin screen_to, $mobile {
			width: auto;
			display: block;
			text-align: center;
			margin: 10px 0px 0px;
			padding: 0px 15px 16px;
		}

		ul {
			margin-top: 10px;

			@mixin screen_to, $mobile {
				margin-top: 11px;
			}
		}
		li {
			& + li {
				margin-top: 1px;

				@mixin screen_to, $mobile {
					margin-top: 2px;
				}
			}
		}

		&_img {
			width: 114px;
			height: 114px;
			border-radius: 50%;
			background: #fff no-repeat;
			box-shadow: 0px 10px 16px rgba(#8c8c8c, .48);
			background-size: cover;

			@mixin screen_to, $mobile {
				width: 76px;
				height: 76px;
				margin: 0px auto;
			}
		}
		&_head {
			width: 100%;
			padding-top: 35px;
			font: _font(16, 20, $f1);

			@mixin screen_to, $mobile {
				font-size: 14px;
				padding-top: 22px;
			}
		}
		&_post {
			font: _font(22, 26, $f1);

			@mixin screen_to, $mobile {
				font-size: 18px;
			}
		}
		&_card {
			width: 100%;
			display: flex;
			flex-flow: wrap;
			margin-top: 35px;
			align-self: flex-end;

			@mixin screen_to, $mobile {
				display: block;
				margin-top: 16px;
			}
		}
		&_info {
			flex-grow: 1;
			font: _font(16, 20, $f1);
			padding: 4px 0px 0px 30px;

			@mixin screen_to, $mobile {
				font-size: 14px;
				padding: 6px 0px 0px;
			}

			ul {
				color: $c4;
			}
			li {
				&:before {
					content: normal;
				}
			}
		}
		&_name {
			font: _font(22, 26, $f1);

			@mixin screen_to, $mobile {
				font-size: 18px;
			}
		}

		&:nth-child(2n) {
			border-color: $cb2;
		}
		&:nth-child(4n) {
			border-color: $cb4;
		}
		&:nth-child(4n-1) {
			border-color: $cb3;
		}
	}
}