html {
	&.remodal-is-locked {
		overflow: hidden;
		touch-action: none;
	}
}

.remodal {
	width: 100%;
	outline: none;
	display: none;
	text-align: left;
	position: relative;
	vertical-align: middle;
	text-size-adjust: 100%;
	transform: translateZ(0);

	&-bg {
		&.remodal-is-opened,
		&.remodal-is-opening {
			filter: blur(3px);
		}
	}
	&-overlay {
		top: -5000px;
		z-index: 1500;
		left: -5000px;
		display: none;
		right: -5000px;
		bottom: -5000px;
		position: fixed;
		backface-visibility: hidden;
		background: rgba(#020204, .5);

		&.remodal-is-opening,
		&.remodal-is-closing {
			animation-duration: 0.3s;
			animation-fill-mode: forwards;
		}
		&.remodal-is-opening {
			animation-name: remodal-overlay-opening-keyframes;
		}
		&.remodal-is-closing {
			animation-name: remodal-overlay-closing-keyframes;
		}
	}
	&-wrapper {
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		padding: 20px;
		z-index: 1500;
		display: none;
		overflow: auto;
		position: fixed;
		text-align: center;
		backface-visibility: hidden;

		@mixin screen_to, $mobile {
			padding: 0px;
		}

		&:after {
			content: '';
			height: 100%;
			margin-left: -0.05em;
			display: inline-block;
			vertical-align: middle;
		}
	}

	&-is-opening,
	&-is-closing {
		animation-duration: 0.3s;
		animation-fill-mode: forwards;
	}
	&-is-opening {
		animation-name: remodal-opening-keyframes;
	}
	&-is-closing {
		animation-name: remodal-closing-keyframes;
	}
	&-is-initialized {
		display: inline-block;
	}
}

/*#region [keyframes][:]*/
@keyframes remodal-opening-keyframes {
	from {
		opacity: 0;
		transform: scale(1.05);
	}
	to {
		opacity: 1;
		filter: blur(0);
		transform: none;
	}
}
@keyframes remodal-closing-keyframes {
	from {
		transform: scale(1);
		opacity: 1;
	}
	to {
		opacity: 0;
		filter: blur(0);
		transform: scale(0.95);
	}
}
@keyframes remodal-overlay-opening-keyframes {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes remodal-overlay-closing-keyframes {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
/*#endregion [keyframes][;]*/