@font-face {
	font-family: 'Icons';
	src: url('../fonts/icons.eot');
	src: url('../fonts/icons.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/icons.woff2') format('woff2'),
		 url('../fonts/icons.woff') format('woff'),
		 url('../fonts/icons.ttf') format('truetype'),
		 url('../fonts/icons.svg#icons') format('svg');
	font-weight: normal;
	font-style: normal;
}

$ico_fb: '\e800';
$ico_vk: '\e801';
$ico_tw: '\e802';
$ico_ok: '\e803';
$ico_in: '\e804';
$ico_ig: '\e805';

$ico_logo: '\e815';
$ico_logo_ru: '\e816';
$ico_logo_en: '\e817';

$ico_play: '\e80e';
$ico_done: '\e80f';
$ico_lang: '\e810';
$ico_phone: '\e811';
$ico_filter: '\e812';
$ico_download: '\e813';
$ico_ellipsis: '\e814';

$ico_arrow_l1: '\e806';
$ico_arrow_r1: '\e807';
$ico_arrow_t2: '\e808';
$ico_arrow_b2: '\e809';
$ico_arrow_l2: '\e80a';
$ico_arrow_r2: '\e80b';

$ico_bubble_l1: '\e80c';
$ico_bubble_r1: '\e80d';