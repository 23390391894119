.mnets {
	font: 0/0 serif;

	&_lnk {
		color: $c4;
		border: none;
		font: 28px/1 $ico;
		vertical-align: top;
		display: inline-block;

		@mixin screen_to, $mobile {
			font-size: 20px;
		}

		&._fb {
			&:after {
				content: $ico_fb;
			}
		}
		&._vk {
			&:after {
				content: $ico_vk;
			}
		}
		&._tw {
			&:after {
				content: $ico_tw;
			}
		}
		&._ok {
			&:after {
				content: $ico_ok;
			}
		}
		&._in {
			&:after {
				content: $ico_in;
			}
		}
		&._ig {
			&:after {
				content: $ico_ig;
			}
		}
		& + & {
			margin-left: 20px;

			@mixin screen_to, $mobile {
				margin-left: 15px;
			}
		}
		&:hover {
			&._fb {
				color: $fb;
			}
			&._vk {
				color: $vk;
			}
			&._tw {
				color: $tw;
			}
			&._ok {
				color: $ok;
			}
			&._in {
				color: $in;
			}
			&._ig {
				color: $ig;
			}
		}
	}
}