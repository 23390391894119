.experts {
	display: flex;
	flex-flow: wrap;
	font: 0/0 serif;
	max-width: 988px;
	margin: 60px auto;
	padding: 0px 20px;

	@mixin screen_to, $mobile {
		margin: 40px auto;
		padding: 0px 15px;
		max-width: $mwmbl;
	}

	&_item {
		color: $c3;
		min-height: 150px;
		position: relative;
		margin: 40px 20px 0px;
		width: calc(50% - 40px);
		padding: 6px 0px 0px 170px;

		@mixin screen_to, $mobile {
			width: 100%;
			margin: 0px;
			min-height: 93px;
			padding: 0px 0px 0px 114px;
		}

		&_img {
			top: 0px;
			left: 0px;
			width: 150px;
			height: 150px;
			position: absolute;
			border-radius: 50%;
			color: currentColor;
			background: #fff no-repeat;
			background-size: cover;

			@mixin screen_to, $mobile {
				top: 5px;
				width: 88px;
				height: 88px;
			}
			
			&:after {
				top: 16px;
				right: -10px;
				display: block;
				font: 28px/1 $ico;
				position: absolute;
				color: currentColor;
				content: $ico_bubble_r1;
				transition: all .4s ease-out-sine;

				@mixin screen_to, $mobile {
					top: 2px;
					right: -14px;
				}
			}
		}
		&_body {
			color: $c5;
			font: _font(16, 20, $f1);

			@mixin screen_to, $mobile {
				margin-top: -5px;
				font: _font(14, 16, $f1);
			}

			ul {
				margin: 15px 0px 0px;

				@mixin screen_to, $mobile {
					margin-top: 13px;
				}
			}
			li {
				@mixin screen_to, $mobile {
					margin: 0px;
				}

				&:before {
					content: normal;
				}
			}
		}
		&_name {
			color: #000;
			display: block;
			font: _font(18, 22, $f1);
		}
		&_post {
			display: block;
			margin-top: 1px;
		}

		& + & {
			@mixin screen_to, $mobile {
				margin-top: 32px;
			}
		}
		&:hover {
			color: $cb1;

			&:nth-child(2n) {
				color: $cb2;
			}
			&:nth-child(4n) {
				color: $cb4;
			}
			&:nth-child(4n-1) {
				color: $cb3;
			}
		}
		&:nth-child(-n + 2) {
			@mixin screen_from, $mobile {
				margin-top: 0px;
			}
		}
	}

	&:last-child {
		margin-bottom: 0px;
	}
	&:first-child {
		margin-top: 0px;
	}
}