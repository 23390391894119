.ubait {
	color: #000;
	font: 0/0 serif;
	margin: 60px 0px;
	text-align: center;
	background: #f4f7fa;
	padding: 52px 0px 40px;
	
	@mixin screen_to, $mobile {
		margin: 40px 0px;
		padding: 24px 0px 30px;
	}

	&_box {
		max-width: 948px;
		margin: 0px auto;
		padding: 0px 20px;

		@mixin screen_to, $mobile {
			max-width: $mwmbl;
			padding: 0px 15px;
		}

		&._s2 {
			max-width: 640px;

			@mixin screen_to, $mobile {
				max-width: $mwmbl;
			}
		}
	}
	&_btn {
		margin-top: 36px;

		@mixin screen_to, $mobile {
			margin-top: 26px;
		}

		&:nth-child(2) {
			margin: 0px;
		}
	}
	&_name {
		display: block;
		margin-bottom: 22px;
		font: _font(36, 40, $f1);

		@mixin screen_to, $mobile {
			margin-bottom: 24px;
			font: _font(24, 28, $f1);
		}

		&._s2m {
			@mixin screen_to, $mobile {
				padding-top: 1px;
				margin-bottom: 25px;
				font: _font(18, 22, $f1);
			}
		}
	}
	&_text {
		margin-top: -5px;
		font: _font(14, 18, $f1);

		p {
			margin: 0px;

			& + p {
				margin-top: 12px;
			}
		}
	}

	&._c2 {
		color: #fff;
		background: $c1;
	}
	& + & {
		margin-top: -60px;
	}
	&:last-child {
		margin-bottom: 0px;
	}
	&:first-child {
		margin-top: 0px;
	}
}