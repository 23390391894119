th,
td {
	font-weight: normal;
	padding: 18px 15px 17px;
	border: 0px solid #000;

	&:last-child {
		padding-right: 0px;
	}
	&:first-child {
		padding-left: 0px;
	}
}
table {
	margin: 0px;
	border: none;
	text-align: left;
	border-collapse: collapse;
}

.table {
	width: 100%;
}

._tb_bg {
}
._tb_bd {
	border-width: 1px;
}
._tb_bdt {
	border-top-width: 1px;
}
._tb_bdl {
	border-left-width: 1px;
}
._tb_bdr {
	border-right-width: 1px;
}
._tb_bdb {
	border-bottom-width: 1px;
}
._tb_fix {
	table-layout: fixed;
}
._tb_wide {
	width: 100%;
}