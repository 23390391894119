h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	margin: 0px;
	font-style: normal;
	font-weight: normal;

	& + p,
	& + ol,
	& + ul {
		margin-top: calc($p_cl * -1);

		@mixin screen_to, $mobile {
			margin-top: calc($p_cl_m * -1);
		}
	}

	&:empty {
		display: none;
	}
	&:last-child {
		margin-bottom: 0px;
	}
}

h1, .h1 {
	font: $h1_font;

	@mixin screen_to, $mobile {
		font-size: $h1_sz_m;
	}

	@if $font_debug == 1 {
		background-color: #8cade5;
	}
}
h2, .h2 {
	$_h2_mt: calc($h2_mt - $h2_cl);
	$_h2_mb: calc($h2_mb - $h2_bl);

	$_h2_mt_m: calc($h2_mt_m - $h2_cl_m);
	$_h2_mb_m: calc($h2_mb_m - $h2_bl_m);

	$_h2h3_mt: calc($h3_mt - ($h2_bl + $h3_cl));
	$_h2h3_mt_m: calc($h3_mt_m - ($h2_bl_m + $h3_cl_m));

	$_h2h4_mt: calc($h4_mt - ($h2_bl + $h4_cl));
	$_h2h4_mt_m: calc($h4_mt_m - ($h2_bl_m + $h4_cl_m));

	$_h2h5_mt: calc($h5_mt - ($h2_bl + $h5_cl));
	$_h2h5_mt_m: calc($h5_mt_m - ($h2_bl_m + $h5_cl_m));

	$_h2h6_mt: calc($h6_mt - ($h2_bl + $h6_cl));
	$_h2h6_mt_m: calc($h6_mt_m - ($h2_bl_m + $h6_cl_m));

	font: $h2_font;
	margin: $_h2_mt 0px $_h2_mb;

	@mixin screen_to, $mobile {
		font-size: $h2_sz_m;
		margin: $_h2_mt_m 0px $_h2_mb_m;
	}

	& + h3, & + .h3 {
		@if $_h2_mb < $_h2h3_mt {
			margin-top: $_h2h3_mt;
		} @else {
			margin-top: calc($_h2h3_mt - $_h2_mb);
		}

		@mixin screen_to, $mobile {
			@if $_h2_mb_m < $_h2h3_mt_m {
				margin-top: $_h2h3_mt_m;
			} @else {
				margin-top: calc($_h2h3_mt_m - $_h2_mb_m);
			}
		}
	}
	& + h4, & + .h4 {
		@if $_h2_mb < $_h2h4_mt {
			margin-top: $_h2h4_mt;
		} @else {
			margin-top: calc($_h2h4_mt - $_h2_mb);
		}

		@mixin screen_to, $mobile {
			@if $_h2_mb_m < $_h2h4_mt_m {
				margin-top: $_h2h4_mt_m;
			} @else {
				margin-top: calc($_h2h4_mt_m - $_h2_mb_m);
			}
		}
	}
	& + h5, & + .h5 {
		@if $_h2_mb < $_h2h5_mt {
			margin-top: $_h2h5_mt;
		} @else {
			margin-top: calc($_h2h5_mt - $_h2_mb);
		}

		@mixin screen_to, $mobile {
			@if $_h2_mb_m < $_h2h5_mt_m {
				margin-top: $_h2h5_mt_m;
			} @else {
				margin-top: calc($_h2h5_mt_m - $_h2_mb_m);
			}
		}
	}
	& + h6, & + .h6 {
		@if $_h2_mb < $_h2h6_mt {
			margin-top: $_h2h6_mt;
		} @else {
			margin-top: calc($_h2h6_mt - $_h2_mb);
		}

		@mixin screen_to, $mobile {
			@if $_h2_mb_m < $_h2h6_mt_m {
				margin-top: $_h2h6_mt_m;
			} @else {
				margin-top: calc($_h2h6_mt_m - $_h2_mb_m);
			}
		}
	}

	@if $font_debug == 1 {
		background-color: #76fb3e;
	}
}
h3, .h3 {
	$_h3_mt: calc($h3_mt - $h3_cl);
	$_h3_mb: calc($h3_mb - $h3_bl);
	
	$_h3_mt_m: calc($h3_mt_m - $h3_cl_m);
	$_h3_mb_m: calc($h3_mb_m - $h3_bl_m);

	$_h3h4_mt: calc($h4_mt - ($h3_bl + $h4_cl));
	$_h3h4_mt_m: calc($h4_mt_m - ($h3_bl_m + $h4_cl_m));

	$_h3h5_mt: calc($h5_mt - ($h3_bl + $h5_cl));
	$_h3h5_mt_m: calc($h5_mt_m - ($h3_bl_m + $h5_cl_m));

	$_h3h6_mt: calc($h6_mt - ($h3_bl + $h6_cl));
	$_h3h6_mt_m: calc($h6_mt_m - ($h3_bl_m + $h6_cl_m));

	font: $h3_font;
	margin: $_h3_mt 0px $_h3_mb;

	@mixin screen_to, $mobile {
		font-size: $h3_sz_m;
		margin: $_h3_mt_m 0px $_h3_mb_m;
	}

	& + h4, & + .h4 {
		@if $_h3_mb < $_h3h4_mt {
			margin-top: $_h3h4_mt;
		} @else {
			margin-top: calc($_h3h4_mt - $_h3_mb);
		}

		@mixin screen_to, $mobile {
			@if $_h3_mb_m < $_h3h4_mt_m {
				margin-top: $_h3h4_mt_m;
			} @else {
				margin-top: calc($_h3h4_mt_m - $_h3_mb_m);
			}
		}
	}
	& + h5, & + .h5 {
		@if $_h3_mb < $_h3h5_mt {
			margin-top: $_h3h5_mt;
		} @else {
			margin-top: calc($_h3h5_mt - $_h3_mb);
		}

		@mixin screen_to, $mobile {
			@if $_h3_mb_m < $_h3h5_mt_m {
				margin-top: $_h3h5_mt_m;
			} @else {
				margin-top: calc($_h3h5_mt_m - $_h3_mb_m);
			}
		}
	}
	& + h6, & + .h6 {
		@if $_h3_mb < $_h3h6_mt {
			margin-top: $_h3h6_mt;
		} @else {
			margin-top: calc($_h3h6_mt - $_h3_mb);
		}

		@mixin screen_to, $mobile {
			@if $_h3_mb_m < $_h3h6_mt_m {
				margin-top: $_h3h6_mt_m;
			} @else {
				margin-top: calc($_h3h6_mt_m - $_h3_mb_m);
			}
		}
	}

	@if $font_debug == 1 {
		background-color: #a96488;
	}
}
h4, .h4 {
	$_h4_mt: calc($h4_mt - $h4_cl);
	$_h4_mb: calc($h4_mb - $h4_bl);

	$_h4_mt_m: calc($h4_mt_m - $h4_cl_m);
	$_h4_mb_m: calc($h4_mb_m - $h4_bl_m);

	$_h4h5_mt: calc($h5_mt - ($h4_bl + $h5_cl));
	$_h4h5_mt_m: calc($h5_mt_m - ($h4_bl_m + $h5_cl_m));

	$_h4h6_mt: calc($h6_mt - ($h4_bl + $h6_cl));
	$_h4h6_mt_m: calc($h6_mt_m - ($h4_bl_m + $h6_cl_m));

	font: $h4_font;
	margin: $_h4_mt 0px $_h4_mb;

	@mixin screen_to, $mobile {
		font-size: $h4_sz_m;
		margin: $_h4_mt_m 0px $_h4_mb_m;
	}

	& + h5, & + .h5 {
		@if $_h4_mb < $_h4h5_mt {
			margin-top: $_h4h5_mt;
		} @else {
			margin-top: calc($_h4h5_mt - $_h4_mb);
		}

		@mixin screen_to, $mobile {
			@if $_h4_mb_m < $_h4h5_mt_m {
				margin-top: $_h4h5_mt_m;
			} @else {
				margin-top: calc($_h4h5_mt_m - $_h4_mb_m);
			}
		}
	}
	& + h6, & + .h6 {
		@if $_h4_mb < $_h4h6_mt {
			margin-top: $_h4h6_mt;
		} @else {
			margin-top: calc($_h4h6_mt - $_h4_mb);
		}

		@mixin screen_to, $mobile {
			@if $_h4_mb_m < $_h4h6_mt_m {
				margin-top: $_h4h6_mt_m;
			} @else {
				margin-top: calc($_h4h6_mt_m - $_h4_mb_m);
			}
		}
	}

	@if $font_debug == 1 {
		background-color: #bbd639;
	}
}
h5, .h5 {
	$_h5_mt: calc($h5_mt - $h5_cl);
	$_h5_mb: calc($h5_mb - $h5_bl);

	$_h5_mt_m: calc($h5_mt_m - $h5_cl_m);
	$_h5_mb_m: calc($h5_mb_m - $h5_bl_m);

	$_h5h6_mt: calc($h6_mt - ($h5_bl + $h6_cl));
	$_h5h6_mt_m: calc($h6_mt_m - ($h5_bl_m + $h6_cl_m));

	font: $h5_font;
	margin: $_h5_mt 0px $_h5_mb;

	@mixin screen_to, $mobile {
		font-size: $h5_sz_m;
		margin: $_h5_mt_m 0px $_h5_mb_m;
	}

	& + h6, & + .h6 {
		@if $_h5_mb < $_h5h6_mt {
			margin-top: $_h5h6_mt;
		} @else {
			margin-top: calc($_h5h6_mt - $_h5_mb);
		}

		@mixin screen_to, $mobile {
			@if $_h5_mb_m < $_h5h6_mt_m {
				margin-top: $_h5h6_mt_m;
			} @else {
				margin-top: calc($_h5h6_mt_m - $_h5_mb_m);
			}
		}
	}

	@if $font_debug == 1 {
		background-color: #e8cfd3;
	}
}
h6, .h6 {
	$_h6_mt: calc($h6_mt - $h6_cl);
	$_h6_mb: calc($h6_mb - $h6_bl);

	$_h6_mt_m: calc($h6_mt_m - $h6_cl_m);
	$_h6_mb_m: calc($h6_mb_m - $h6_bl_m);

	font: $h6_font;
	margin: $_h6_mt 0px $_h6_mb;

	@mixin screen_to, $mobile {
		font-size: $h6_sz_m;
		margin: $_h6_mt_m 0px $_h6_mb_m;
	}

	@if $font_debug == 1 {
		background-color: #519449;
	}
}