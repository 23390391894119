.sod {
	&_list {
		display: block;
		overflow-y: auto;
		max-height: 200px;

		&_wrapper {
			top: 100%;
			left: -1px;
			color: $c1;
			z-index: 1;
			opacity: 0;
			height: auto;
			overflow: hidden;
			margin-top: -5px;
			padding-top: 5px;
			background: #fff;
			position: absolute;
			visibility: hidden;
			pointer-events: none;
			border: 1px solid $c3;
			width: calc(100% + 2px);
			transform: translateY(5px);
			border-width: 0px 1px 1px 1px;
			border-radius: 0px 0px 5px 5px;
			transition:
				opacity .4s ease-out-sine,
				transform .4s ease-out-sine,
				visibility .4s ease-out-sine;
		}
	}
	&_label {
		height: 38px;
		color: #000;
		display: block;
		overflow: hidden;
		padding: 0px 40px;
		white-space: nowrap;
		text-overflow: ellipsis;
		font: _font(16, 38, $f1);

		@mixin screen_to, $mobile {
			height: 36px;
			font: _font(14, 34, $f1);
			padding: 0px 28px 0px 15px;
		}
	}
	&_select {
		z-index: 50;
		display: block;
		cursor: pointer;
		user-select: none;
		background: #fff;
		position: relative;
		text-align: center;
		border-radius: 5px;
		border: 1px solid $c3;
		-webkit-touch-callout: none;
		transition: border .4s ease-out-sine;

		&:after,
		&:before {
			top: 50%;
			width: 11px;
			height: 1px;
			content: '';
			color: #000;
			display: block;
			position: absolute;
			background: currentColor;
			transition: all .2s ease-out-sine;
		}
		&:after {
			right: 10px;
			transform: rotate(-45deg);
		}
		&:before {
			right: 18px;
			transform: rotate(45deg);
		}

		select {
			opacity: 0;
			width: 0px;
			height: 0px;
			top: -10000px;
			left: -10000px;
			position: fixed;
			font: 14px/18px $f1;
		}

		&.open {
			z-index: 550;

			&:after {
				transform: rotate(45deg);
			}
			&:before {
				transform: rotate(-45deg);
			}

			.sod_list_wrapper {
				opacity: 1;
				transform: none;
				visibility: visible;
				pointer-events: auto;
			}

			&.above {
				.sod_list_wrapper {
					transform: none;
				}
			}
		}
		&.above {
			.sod {
				&_list {
					&_wrapper {
						top: auto;
						bottom: 100%;
						margin: 0px 0px -5px;
						padding: 0px 0px 5px;
						transform: translateY(-5px);
						border-width: 1px 1px 0px 1px;
						border-radius: 5px 5px 0px 0px;
					}
				}
				&_option {
					padding: 11px 20px 0px;

					&:after {
						content: '';
					}
					&:before {
						content: normal;
					}
				}
			}
		}
		&.touch {
			select {
				top: 0px;
				left: 0px;
				z-index: 10;
				width: 100%;
				height: 100%;
				position: absolute;
				-webkit-appearance: menulist-button;
				display: block !important;
			}
		}
		&._error {
			border-color: $cerror;
		}
		&.disabled {
			cursor: default;
			border-color: #000;

			&.open {
				.sod_list_wrapper {
					display: none;
				}
			}
		}

		&:hover {
			&:after,
			&:before {
				color: $c1;
			}
		}
	}
	&_option {
		color: $c5;
		display: block;
		cursor: pointer;
		font: 16px/1 $f1;
		padding: 0px 20px 10px;
		transition: color .2s ease-out-sine;

		&:after,
		&:before {
			height: 1px;
			display: block;
			background: $c3;
			margin: 0px 20px;
		}
		&:after {
			margin-top: 10px;
		}
		&:before {
			content: '';
			margin-bottom: 11px;
		}

		&:hover {
			color: $c1;
		}
		&.disabled,
		&.selected,
		&.is-placeholder
		/*&[data-value=""]*/ {
			display: none;
		}
	}
	&_prefix {
		font-family: $f1;
		margin-right: 5px;
	}
}