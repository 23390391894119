.form {
	max-width: 668px;
	padding-left: 200px;

	@mixin screen_to, $mobile {
		padding: 0px;
	}

	sup {
		color: $c1;
		top: em(-2, 18);
		font-size: em(22, 18);
	}

	/*#region [scheme][:]*/
	&_row {
		display: flex;
		flex-flow: wrap;
		max-width: 668px;
		justify-content: flex-end;
		margin: 20px 0px 0px -200px;

		@mixin screen_to, $mobile {
			display: block;
			margin: 16px 0px 0px;
		}

		&:after {
			width: 100%;
			content: '';
			display: table;
		}

		&_name {
			width: 200px;
			display: flex;
			text-align: right;
			align-items: center;
			padding-right: 20px;
			font: _font(16, 20, $f1);
			justify-content: flex-end;

			@mixin screen_to, $mobile {
				width: auto;
				display: block;
				font: 14px/1 $f1;
				text-align: left;
				padding: 0px 0px 4px;
			}

			span {
				max-width: 100%;
				vertical-align: top;
				display: inline-block;
			}

			&._num {
				font: _font(26, 30, $f1);

				@mixin screen_to, $mobile {
					font-size: 18px;
				}
			}
			&._tlbl {
				margin-top: 19px;

				@mixin screen_to, $mobile {
					margin-top: 0px;
				}
			}
			&._tarea {
				padding-top: 11px;
				align-items: flex-start;

				@mixin screen_to, $mobile {
					padding-top: 0px;
				}
			}
		}
		&_head {
			width: 100%;
			padding-left: 200px;
		}
		&_body {
			flex-grow: 1;
			flex-basis: 0;
			display: flex;
			flex-flow: wrap;
			position: relative;
			vertical-align: top;

			@mixin screen_to, $mobile {
				display: block;
			}

			&:after {
				width: 100%;
				content: '';
				display: table;
			}

			&._dates {
				@mixin screen_to, $mobile {
					display: inline-block;
				}
			}
			&:first-child {
				margin-left: 200px;

				@mixin screen_to, $mobile {
					margin: 0px;
				}
			}
		}
	}
	&_set {
		position: relative;

		@mixin screen_to, $mobile {
			padding-right: 22px;
		}

		&:after {
			top: -5px;
			left: 100%;
			width: 6px;
			content: '';
			display: block;
			margin-left: 30px;
			position: absolute;
			height: calc(100% + 10px);
			border-top: 2px dashed $c3;
			border-right: 2px dashed $c3;
			border-bottom: 2px dashed $c3;

			@mixin screen_to, $mobile {
				left: auto;
				right: 0px;
				margin-left: 0px;
			}
		}

		&_remove {
			top: 50%;
			left: 100%;
			color: $c0;
			z-index: 1;
			width: 30px;
			height: 30px;
			background: #fff;
			border-radius: 50%;
			position: absolute;
			margin: -15px 0px 0px 20px;

			@mixin screen_to, $mobile {
				left: auto;
				width: 22px;
				right: -10px;
				height: 22px;
			}

			&:after,
			&:before {
				top: 50%;
				left: 50%;
				content: '';
				display: block;
				position: absolute;
				background: currentColor;
				transform: translate(-50%, -50%) rotate(45deg);
			}
			&:after {
				width: 16px;
				height: 2px;

				@mixin screen_to, $mobile {
					width: 12px;
				}
			}
			&:before {
				width: 2px;
				height: 16px;

				@mixin screen_to, $mobile {
					height: 12px;
				}
			}

			&:hover {
				color: #fff;
				background: $c0;
			}
		}

		& + & {
			margin-top: 20px;
		}
	}
	&_cell {
		flex-grow: 1;
		flex-basis: 0;
		align-self: flex-end;

		&_name {
			display: block;
			margin: -5px 0px 6px;
			font: _font(14, 18, $f1);

			@mixin screen_to, $mobile {
				margin-bottom: 2px;
			}
		}
		&_input,
		&_select {
			&._s1o2m {
				@mixin screen_to, $mobile {
					width: 50%;
				}
			}
		}

		& + & {
			margin-left: 14px;

			@mixin screen_to, $mobile {
				margin: 20px 0px 0px;
			}
		}
	}
	&_hide {
		display: none;
		margin-top: 20px;

		@mixin screen_to, $mobile {
			margin-top: 16px;
		}

		&._show {
			display: block;
		}
	}
	&_foot {
		display: flex;
		flex-flow: wrap;
		margin-top: 30px;
		align-items: center;
		justify-content: space-between;

		@mixin screen_to, $mobile {
			display: block;
			text-align: center;
			margin: 26px -15px 0px;
		}

		&._popup {
			@mixin screen_to, $mobile {
				margin: 26px -10px 0px;
			}
		}
	}
	/*#endregion [scheme][;]*/

	/*#region [object][:]*/
	&_add {
		display: block;
		margin: 20px 0px 30px;
	}
	&_file {
		display: block;
		min-height: 40px;
		user-select: none;
		position: relative;
		padding-left: 185px;
		width: calc(100% - 200px);

		@mixin screen_to, $mobile {
			width: auto;
			padding: 0px;
			min-height: auto;
		}

		&_btn {
			top: 50%;
			left: 0px;
			width: 165px;
			height: 40px;
			color: #000;
			display: block;
			cursor: pointer;
			overflow: hidden;
			padding: 0px 10px;
			margin-top: -20px;
			background: #fff;
			text-align: center;
			position: absolute;
			white-space: nowrap;
			border-radius: 20px;
			text-overflow: ellipsis;
			font: _font(14, 38, $f1);
			border: 1px solid #f5f6f7;
			transition: all .2s ease-in-out-sine;
			box-shadow: 0px 2px 5px rgba(#000, .05);

			@mixin screen_to, $mobile {
				top: auto;
				margin: 0px;
				position: relative;
			}

			&:empty {
				&:after {
					content: attr(data-txt);
				}
			}
			&:hover {
				color: #fff;
				background: $c1;
			}
			&:not(:empty) {
				background: #40d086;
			}
		}
		&_note {
			color: $c5;
			display: block;
			font: _font(14, 18, $f1);

			@mixin screen_to, $mobile {
				margin-top: 5px;
				font: _font(12, 16, $f1);
			}
		}

		&._error {
			^&_btn {
				color: #fff;
				background: $cerror;

				&:hover {
					background: $c1;
				}
			}
		}
	}
	&_note {
		color: $c5;
		display: block;
		margin-bottom: 16px;
		font: _font(14, 18, $f1);

		@mixin screen_to, $mobile {
			font: _font(12, 14, $f1);
		}
	}
	&_field {
		align-items: center;
		vertical-align: top;
		display: inline-flex;

		@mixin screen_to, $mobile {
			float: left;
			clear: both;
		}

		&_label {
			flex-grow: 1;
			font: _font(14, 18, $f1);

			&:last-child {
				margin-left: 10px;
			}
			&:first-child {
				margin-right: 10px;
			}
		}
		&_input {
			width: 146px;
		}

		& + & {
			margin-left: 15px;

			@mixin screen_to, $mobile {
				margin: 10px 0px 0px;
			}
		}
		&._date {
			@mixin screen_to, $mobile {
				width: 100%;
			}
		}
		&._hover {
			cursor: pointer;
		}
	}
	&_title {
		font-family: $f1;
		padding-bottom: 11px;
		margin: 60px auto 30px;
		border-bottom: 6px solid $cb1;

		@mixin screen_to, $mobile {
			padding-bottom: 3px;
			margin: 30px auto 22px;
			font: _font(18, 22, $f1);
		}

		&:nth-of-type(2n) {
			border-color: $cb2;
		}
		&:nth-of-type(4n) {
			border-color: $cb4;
		}
		&:nth-of-type(4n-1) {
			border-color: $cb3;
		}
	}
	&_agree {
		margin-top: 30px;
		position: relative;
		padding-left: 40px;
		font: _font(14, 18, $f1);

		label {
			cursor: pointer;
		}
		.checkbox {
			top: 0px;
			left: 0px;
			position: absolute;
		}

		& + & {
			margin-top: 26px;
		}
	}
	&_input,
	&_select {
		width: 100%;
		flex-grow: 1;
		flex-basis: 0;
		vertical-align: top;
		display: inline-block;

		@mixin screen_to, $mobile {
			width: 100%;
		}

		&._date {
			width: 120px;

			@mixin screen_to, $mobile {
				width: 100px;
			}
		}

		&._s2 {
			width: 145px;
		}
		&._s1o3 {
			width: calc(33.3334% - (14px - 14px * 1/3));

			@mixin screen_to, $mobile {
				width: 100%;
			}

			&:nth-child(3n-1) {
				margin: 0px 14px;

				@mixin screen_to, $mobile {
					margin: 20px 0px;
				}
			}
		}
	}
	&_submit {
		width: 146px;
		padding: 0px;

		&:disabled {
			opacity: .6;
		}
	}
	&_before {
		color: $c5;
		max-width: 468px;
		margin-bottom: 16px;
		font: _font(14, 18, $f1);

		@mixin screen_to, $mobile {
			font: _font(12, 14, $f1);
		}
	}
	&_captcha {
		width: 302px;
		height: 76px;
		position: relative;
		border-radius: 3px;
		background: #f5f6f7;

		@mixin screen_to, $mobile {
			margin: 0px auto 20px;
		}
	}
	/*#endregion [object][;]*/

	&:last-child,
	& > :last-child {
		margin-bottom: 0px;
	}
	&:first-child,
	& > :first-child {
		margin-top: 0px;
	}
}

@keyframes rainbow {
	0% {
		background-position: 0% 0%;
	}
	100% {
		background-position: 100% 0%;
	}
}