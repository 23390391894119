.sstory {
	padding-bottom: 35px;

	@mixin screen_to, $mobile {
		padding: 0px;
		margin: 0px auto;
		max-width: 320px;
	}

	&_item {
		color: $cb1;
		display: block;
		width: calc(33.3334% - (80px - 80px * 1/3));

		@mixin screen_to, $mobile {
			width: 100%;
			margin: 0px;
			text-align: center;
		}

		&_pin {
			color: $c2;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			margin: 18px 0px 15px;
			background: currentColor;
			transition: color .8s ease-in-out-sine .1s;

			@mixin screen_to, $mobile {
				margin: 18px auto 15px;
			}
		}
		&_year {
			color: $c3;
			display: block;
			font: 60px/1 $f1b;
			margin-top: -13px;
			transition: color .8s ease-in-out-sine .1s;
		}
		&_text {
			color: #000;
			display: block;
		}

		&:last-child {
			margin: 0px;
		}
	}
	&_prev,
	&_next {
		color: $c3;
		z-index: 15;
		width: 70px;
		bottom: 0px;
		height: 20px;
		display: block;
		font: 20px/1 $ico;
		position: absolute;

		@mixin screen_to, $mobile {
			top: 20px;
			width: 26px;
			bottom: auto;
			font-size: 10px;
		}

		&:after {
			top: 0px;
			display: block;
			position: absolute;
			transition: transform .2s ease-out-sine;

			@mixin screen_to, $mobile {
				top: 5px;
			}
		}

		&:hover {
			color: $c1;

			&:after {
				transform: none;
			}
		}
		&.swiper-button-disabled {
			opacity: 0;
			visibility: hidden;
		}
	}
	&_prev {
		left: 0px;

		&:after {
			left: 0px;
			content: $ico_arrow_l1;
			transform: translateX(10px);
		}
	}
	&_next {
		right: 0px;

		&:after {
			right: 0px;
			content: $ico_arrow_r1;
			transform: translateX(-10px);
		}
	}
}