.lreviews {
	display: flex;
	flex-flow: wrap;
	text-align: left;
	user-select: none;

	&:after {
		order: 2;
		color: $c3;
		z-index: 1;
		display: block;
		font: 28px/1 $ico;
		position: relative;
		margin: 13px 0px -12px 29px;

		@mixin screen_to, $mobile {
			content: $ico_bubble_l1;
		}
	}

	/*#region [scheme][:]*/
	&_menu {
		z-index: 1;
		flex-grow: 1;
		flex-basis: 0;
		position: relative;

		@mixin screen_to, $mobile {
			order: 1;
			flex-basis: 100%;
		}
	}
	&_view {
		flex-grow: 1;
		display: flex;
		flex-shrink: 0;
		max-width: 462px;
		margin-right: 28px;
		align-items: center;
		border-radius: 20px;
		border: 2px solid $c3;

		@mixin screen_to, $mobile {
			order: 3;
			margin: 0px;
			flex-grow: 0;
			flex-shrink: 1;
			max-width: none;
		}

		&_slide {
			height: 100%;
			display: flex;
			flex-flow: column;
			background: #fff;
			justify-content: center;
			box-shadow: 0px 0px 0px 1000px #fff;
		}

		&._video {
			border-color: #fff;
		}
	}
	/*#endregion [scheme][;]*/
	
	/*#region [object][:]*/
	&_user {
		color: $cb1;
		opacity: .3;
		cursor: pointer;
		min-height: 60px;
		position: relative;
		padding-left: 80px;
		font: _font(16, 20, $f1);
		transition: all .4s ease-out-sine;

		@mixin screen_to, $mobile {
			opacity: 1;
			padding-top: 3px;
			font: _font(12, 16, $f1);
		}

		span {
			display: block;
		}

		&_img {
			top: 0px;
			left: 0px;
			width: 60px;
			height: 60px;
			position: absolute;
			border-radius: 50%;
			color: currentColor;
			background: $c3 no-repeat;
			background-size: cover;
		
			&:after,
			&:before {
				opacity: 0;
				content: '';
				right: 100%;
				display: block;
				position: absolute;
				border-radius: 50%;
				transform: scale(.6);
				background: currentColor;
				transform-origin: 100% 0%;
				transition: all .4s ease-out-sine;

				@mixin screen_to, $mobile {
					content: normal;
				}
			}
			&:after {
				top: 54px;
				width: 24px;
				height: 24px;
				margin-right: 17px;
			}
			&:before {
				top: 50px;
				width: 8px;
				height: 8px;
				margin-right: 10px;
			}
		}
		&_name {
			color: #000;
			font: _font(18, 24, $f2);

			@mixin screen_to, $mobile {
				font-size: 14px;
				margin-bottom: 5px;
			}
		}
		&_post,
		&_firm {
			color: $c5;
		}
	
		& + & {
			margin-top: 26px;
		}
		&:hover,
		&._active {
			opacity: 1;
		}
		&._active {
			cursor: default;
	
			^&_img {
				&:after,
				&:before {
					opacity: 1;
					transform: none;
				}
				&:after {
					transition-delay: .2s;
				}
			}
		}
		&:nth-child(2n) {
			color: $cb2;
		}
		&:nth-child(4n) {
			color: $cb4;
		}
		&:nth-child(4n-1) {
			color: $cb3;
		}
	}
	&_info {
		cursor: pointer;
		position: relative;
		padding: 13px 20px;
		font: _font(24, 30, $f2eli);
		
		@mixin screen_to, $mobile {
			padding: 25px 15px 42px;
			font: _font(14, 18, $f2eli);
		}
	
		&:after {
			left: 29px;
			color: $c3;
			bottom: 100%;
			display: block;
			font: 28px/1 $ico;
			position: absolute;
			margin-bottom: -10px;
		}
	
		p {
			margin: 0px;
	
			&:last-of-type {
				display: inline;
			}
		}
	
		&_box {
			margin: 0 auto;
			max-width: 354px;
		}
		&_dots {
			color: $c3;
			font: 0/0 serif;
			position: relative;
			vertical-align: top;
			display: inline-block;
			margin: 17px 0px 0px 20px;
			transition: all .4s ease-out-sine;
	
			@mixin screen_to, $mobile {
				right: 20px;
				bottom: 20px;
				position: absolute;
			}
	
			i {
				margin: 0px 10px;
			}
			i,
			&:after,
			&:before {
				width: 6px;
				height: 6px;
				content: '';
				border-radius: 50%;
				vertical-align: top;
				display: inline-block;
				background: currentColor;
			}
	
			&._load {
				color: $c1;
	
				i,
				&:after,
				&:before {
					animation: ball-beat .8s ease-out-sine infinite both;
				}
				&:after,
				&:before {
					animation-delay: -0.35s;
				}
			}
		}
	
		&:hover {
			&_dots {
				color: #3151b7;
			}
		}
		&._video {
			cursor: default;
			border-color: #fff;
		}
	}
	&_video {
		width: 150px;
		display: block;
		cursor: pointer;
		margin: 0px auto;
		position: relative;
		transition: all .2s ease-out-sine;

		&_ico {
			display: flex;
			flex-flow: wrap;
			font-size: 25px;
			background: $c3;
			position: relative;
			border-radius: 50%;
			width: em(150, 25);
			height: em(150, 25);
			align-items: center;
			margin: 0px auto 35px;
			
			@mixin screen_to, $mobile {
				font-size: 16px;
				margin-bottom: 15px;
			}

			&:after {
				color: #fff;
				display: block;
				background: $c2;
				margin: 0px auto;
				width: em(76, 25);
				height: em(76, 25);
				text-align: center;
				border-radius: 50%;
				content: $ico_play;
				text-indent: em(10, 25);
				font: 1em/em(76, 25) $ico;
				box-shadow: 0px 0px 0px $c2;
				transition: all .2s ease-out-sine;
			}
		}
		&_name {
			display: block;
			text-align: center;
			font: _font(18, 22, $f1b);
			
			@mixin screen_to, $mobile {
				font-size: 16px;
			}
		}

		&:hover {
			color: $c1;

			^&_ico {
				&:after {
					background: $c1;
					box-shadow: 0px 0px 0px em(19, 25) $c1;
				}
			}
		}
	}
	/*#endregion [object][;]*/

}

