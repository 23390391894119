.mmain {
	cursor: default;
	position: relative;
	text-align: center;
	background: #f4f7fa;
	padding: 40px 20px 10px;

	@mixin screen_to, $mobile {
		top: 49px;
		left: 0px;
		opacity: 0;
		width: 100%;
		padding: 0px;
		z-index: 100;
		display: flex;
		position: fixed;
		text-align: left;
		overflow-y: auto;
		flex-flow: column;
		overflow-x: hidden;
		visibility: hidden;
		border-top: 1px solid $c3;
		height: calc(100vh - 49px);
		transform: translateX(-100%);
		transition: all .2s ease-out-sine;
		-webkit-overflow-scrolling: touch;
	}

	li {
		margin: 0px;
		padding: 0px;
		vertical-align: top;
		display: inline-block;

		@mixin screen_to, $mobile {
			display: block;
		}

		&:before {
			content: normal;
		}
	}

	&_ul1 {
		width: 100%;
		padding: 0px;
		margin: 0px auto;

		@mixin screen_to, $mobile {
			flex-grow: 1;
			flex-shrink: 0;
			max-width: $mwmbl;
		}
	}
	&_ul2 {
		maw-width: 988px;
		margin: 0px auto;
		padding: 0px 20px;

		@mixin screen_to, $mobile {
			padding: 0px;
		}
	}
	&_li1 {
		& + & {
			margin-left: 30px;

			@mixin screen_to, $mobile {
				margin: 0px;
			}
		}

		&-open {
			^^&_sub {
				display: block;
			}
			^^&_lnk1 {
				&:after {
					border-width: 0px 4.5px 6px 4.5px;
					border-color: transparent transparent #adb9cd transparent;
				}
			}
		}
	}
	&_li2 {
		&._prnt {
			display: none;

			@mixin screen_to, $mobile {
				display: block;
			}
		}
	}
	&_sub {
		left: 0px;
		width: 100%;
		bottom: 0px;
		display: none;
		background: #fff;
		position: absolute;

		@mixin screen_to, $mobile {
			position: relative;
			background: #e8eef4;
		}

		&-open {
			display: block;

			@mixin screen_to, $mobile {
				display: none;
			}
		}
	}
	&_lnk1 {
		color: $c1;
		display: block;
		font: 16px/1 $f1b;
		position: relative;
		padding-bottom: 16px;
		text-transform: uppercase;
		border-bottom: 2px solid transparent;

		@mixin screen_to, $mobile {
			font: 18px/1 $f1b;
			padding: 21px 15px 20px;
			border-bottom: 1px solid #dae4f5;
		}

		&:after {
			top: 50%;
			width: 0px;
			right: 15px;
			height: 0px;
			display: block;
			margin-top: -3px;
			position: absolute;
			border-style: solid;
			border-width: 6px 4.5px 0px 4.5px;
			border-color: $c1 transparent transparent transparent;
		}

		&:hover {
			color: $c2;
			border-color: $c2;

			@mixin screen_to, $mobile {
				color: $c1;
				border-color: #dae4f5;
			}
		}
		&-sub {
			&:after {
				@mixin screen_to, $mobile {
					content: '';
				}
			}
		}
		&-open {
			margin-bottom: 60px;

			@mixin screen_to, $mobile {
				margin-bottom: 0px;
			}
		}
		&._active {
			color: #000;
			border-color: #000;

			@mixin screen_to, $mobile {
				border-color: #dae4f5;
			}
		}
	}
	&_lnk2 {
		color: $c1;
		border: none;
		display: block;
		padding: 0px 10px;
		font: _font(16, 50, $f1);

		@mixin screen_to, $mobile {
			font: 18px/1 $f1;
			padding: 21px 15px 20px;
			border-bottom: 1px solid #dae4f5;
		}

		&:hover {
			color: $c2;
			border-color: #dae4f5;
		}
		&._prnt {
			font-family: $f1b;
		}
		&._active {
			color: #000;
		}
	}

	&_btn {
		top: 12px;
		right: 15px;
		width: 26px;
		height: 25px;
		z-index: 150;
		display: none;
		position: absolute;

		@mixin screen_to, $mobile {
			display: block;
		}

		span,
		&:after,
		&:before {
			left: 1px;
			content: '';
			width: 24px;
			height: 3px;
			background: $c1;
			position: absolute;
			transition: all .2s ease-out-sine;
		}
		span {
			top: 11px;
		}
		&:after {
			bottom: 4px;
		}
		&:before {
			top: 4px;
		}

		&._active {
			span {
				opacity: 0;
				transform: scale(0);
			}
			&:after {
				transform: translateY(-7px) rotate(45deg);
			}
			&:before {
				transform: translateY(7px) rotate(-45deg);
			}
		}
	}
	&_foot {
		width: 100%;
		display: none;
		max-width: $mwmbl;
		margin: 40px auto 0px;
		padding: 0px 15px 20px;

		@mixin screen_to, $mobile {
			display: block;
		}
	}
	&_phns {
		&_label {
			display: block;
			font: 14px/1 $f1;
		}

		a {
			border: none;
			color: #000;
			font: 18px/1 $f1b;
		}
		ul {
			margin-top: 15px;
		}
		li {
			& + li {
				margin-top: 4px;
			}
		}
	}
	&_beep {
		display: flex;
		flex-flow: wrap;
		margin-top: 13px;
		justify-content: space-between;

		&_link {
			color: #000;
			font: 14px/16px $f1;
			vertical-align: top;
			display: inline-block;
			border-bottom: 1px solid #000;

			&._call {
				color: $c1;
				border: none;
				font: 14px/16px $f1;
				vertical-align: top;
				display: inline-block;
	
				&:before {
					font: 16px/1 $ico;
					margin-right: 4px;
					vertical-align: top;
					content: $ico_phone;
					display: inline-block;
				}
			}
		}
	}

	&-fix {
		padding: 31px 0px 32px;

		^&_lnk1 {
			border-width: 0px;
			padding-bottom: 0px;

			&-open {
				margin-bottom: 34px;
			}
		}
		^&_lnk2 {
			font: _font(14, 34, $f1);
		}
	}
	&._open {
		opacity: 1;
		transform: none;
		visibility: visible;
	}
}