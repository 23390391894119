p {
	widows: 4;
	orphans: 3;
	margin: 0px;

	&:empty {
		display: none;
	}

	@if $font_debug == 1 {
		background-color: #dbe79e;
	}
}
i,
em {
	font-style: normal;
}
b,
._bold,
strong {
	font-family: $f1b;
	font-weight: normal;
}