.uvideo {
	font: 0/0 serif;
	overflow: hidden;
	max-width: 908px;
	margin: 60px auto;
	padding: 0px 20px;

	@mixin screen_to, $mobile {
		margin: 40px auto;
		padding: 0px 15px;
		max-width: $mwmbl;
	}

	iframe {
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		position: absolute;
	}

	&_box {
		position: relative;
		background: #dbdbdb;
		padding-bottom: 56.25%;
	}

	&:last-child {
		margin-bottom: 0px;
	}
	&:first-child {
		margin-top: 0px;
	}
}