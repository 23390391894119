.fcalc {
	max-width: 754px;
	background: #fff;
	position: relative;
	border-radius: 20px;
	box-shadow: 0px 6px 18px rgba(#000, .05);

	@mixin screen_to, $mobile {
		max-width: $mwmbl;
		border-radius: 0px;
	}

	/*#region [scheme][:]*/
	&_head {
		background: $c1;
		position: relative;
		padding: 59px 40px 14px;
		border-radius: 20px 20px 0px 0px;

		@mixin screen_to, $mobile {
			border-radius: 0px;
			padding: 25px 15px 15px;
		}
	}
	&_body {
		padding: 36px 40px 35px;

		@mixin screen_to, $mobile {
			padding: 17px 15px 16px;
		}
	}
	&_case {
		display: none;
		margin-top: 26px;

		@mixin screen_to, $mobile {
			margin-top: 17px;
		}

		&._show {
			display: block;
		}
	}
	&_pane {
		display: flex;
		flex-flow: wrap;
		margin-top: 40px;
		padding-top: 20px;
		border-top: 1px solid $c3;
		justify-content: space-between;

		@mixin screen_to, $mobile {
			border: none;
			padding: 0px;
			margin-top: 20px;
		}
	}
	&_foot {
		margin-top: 21px;
		position: relative;
		font: _font(16, 20, $f1);

		@mixin screen_to, $mobile {
			margin-top: 11px;
			font: _font(12, 16, $f1);
		}

		&:before {
			top: -2px;
			color: $c1;
			right: 100%;
			content: '*';
			line-height: 1;
			position: absolute;
			font-size: em(22, 16);
		}
	}
	/*#endregion [scheme][;]*/

	/*#region [object][:]*/
	&_sum {
		width: 100%;
		max-width: 340px;
		margin-top: -3px;

		@mixin screen_to, $mobile {
			max-width: none;
			margin-top: 14px;
		}

		&_row {
			display: flex;
			align-items: baseline;
			justify-content: space-between;

			& + & {
				margin-top: 10px;

				@mixin screen_to, $mobile {
					margin-top: 0px;
				}
			}
		}
		&_lbl {
			color: $c4;
			vertical-align: top;
			display: inline-block;
			font: _font(16, 20, $f2i);

			@mixin screen_to, $mobile {
				font: _font(12, 16, $f1);
			}
		}
		&_prm {
			vertical-align: top;
			display: inline-block;
			font: _font(16, 20, $f1);

			@mixin screen_to, $mobile {
				font: _font(14, 18, $f1);
			}

			&._1 {
				font: _font(30, 34, $f1b);

				@mixin screen_to, $mobile {
					font-size: 24px;
				}
			}
		}
	}
	&_note {
		padding: 20px;
		display: none;
		border-radius: 10px;
		border: 2px solid $c3;
		margin: 20px auto -5px;
		font: _font(16, 20, $f1);

		@mixin screen_to, $mobile {
			margin: 15px auto 5px;
			font: _font(12, 16, $f1);
		}

		&._show {
			display: block;
		}
	}
	&_close {
		top: 28px;
		right: 28px;
		width: 20px;
		height: 20px;
		color: #fff;
		position: absolute;

		@mixin screen_to, $mobile {
			top: 13px;
			right: 13px;
		}

		&:after,
		&:before {
			top: 50%;
			left: 50%;
			content: '';
			display: block;
			position: absolute;
			transform: rotate(45deg);
			background: currentColor;
		}
		&:after {
			width: 20px;
			height: 2px;
			margin: -1px 0px 0px -10px;
		}
		&:before {
			width: 2px;
			height: 20px;
			margin: -10px 0px 0px -1px;
		}

		&:hover {
			color: $chover;
		}
	}
	&_title {
		color: #fff;
		font: _font(30, 34, $f1);

		@mixin screen_to, $mobile {
			font: _font(18, 22, $f1);
		}
	}
	&_label {
		display: block;
		margin: 26px 0px 3px;
		font: _font(16, 20, $f1);

		@mixin screen_to, $mobile {
			font: 14px/1 $f1;
			margin: 17px 0px 4px;
		}

		&:first-child {
			margin-top: 0px;
		}
	}
	&_input {
		max-width: 100px;
	}
	&_select {
		display: block;
		max-width: 320px;
	}
	/*#endregion [object][;]*/

}