.lcase {
	padding: 0px 20px;
	position: relative;
	margin: 40px auto 0px;

	@mixin screen_to, $mobile {
		padding: 0px;
		margin-top: 30px;
		text-align: center;
	}

	&_box {
		margin: 0px auto;
		max-width: 851px;
		padding-top: 19px;
		position: relative;
		will-change: filter;
		border-top: 1px solid $c3;
		transition: all .4s ease-out-sine;
		filter: url('data:image/svg+xml;utf8,&lt;svg xmlns=\"http://www.w3.org/2000/svg\"&gt;&lt;filter id=\"grayscale\"&gt;&lt;feColorMatrix type=\"matrix\" values=\"0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\"/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale');
		filter: grayscale(100%);

		@mixin screen_to, $mobile {
			filter: none;
		}

		&:after {
			width: 100%;
			content: '';
			display: table;
		}
		&:before {
			top: -1px;
			left: 50%;
			width: 0%;
			opacity: 0;
			height: 1px;
			content: '';
			display: block;
			background: $c0;
			position: absolute;
			transition: all .4s ease-out-sine;
			transform: translateX(-50%) scaleY(3);
		}

		.is_clean & {
			opacity: 0;
		}
	}
	&_btn {
		transition: all .2s ease-out-sine;
	}
	&_more {
		clear: both;
		float: right;
		margin-top: 20px;
		transition: all .2s ease-out-sine;

		@mixin screen_to, $mobile {
			float: none;
			margin: 20px auto 0px;
		}
	}
	&_title {
		z-index: 10;
		color: #000;
		position: relative;
		margin: 0px 0px 23px;
		font: _font(24, 30, $f1b);

		&:after {
			top: -3px;
			right: 100%;
			z-index: -1;
			opacity: .6;
			height: 38px;
			display: block;
			position: absolute;
			pointer-events: none;
			transform: rotate(1deg);
			transform-origin: 100% 0%;
			background-image:
				linear-gradient(-90deg,
					rgba(#000, 0) 0%,
					rgba(#000, .07) 15%,
					rgba(#000, .1) 21%,
					rgba(#000, .06) 31%,
					rgba(#000, 0) 100%
				);
		}

		&_lbl {
			border: none;
			position: relative;
			color: currentColor;
			vertical-align: top;
			display: inline-block;
			transform: translateZ(0);

			&:after {
				opacity: .6;
				z-index: -1;
				height: 38px;
				display: block;
				position: absolute;
				pointer-events: none;
				transform-origin: 100% 0%;
				background-image:
					linear-gradient(-90deg,
						rgba(#000, 0) 0%,
						rgba(#000, .07) 15%,
						rgba(#000, .1) 21%,
						rgba(#000, .06) 31%,
						rgba(#000, 0) 100%
					);
			}

			/*#region [type][:]*/
			&._t1 {
				&:after {
					top: -3px;
					right: -3px;
					content: '';
					transform: rotate(1deg);
					width: calc(100% + 115px);

					@mixin screen_to, $mobile {
						content: normal;
					}
				}
			}
			&._t2 {
				&:after {
					top: -4px;
					left: -3px;
					content: '';
					width: calc(100% + 32px);
					transform: rotate(-1deg);
					background-image:
						linear-gradient(-20deg,
							rgba(#000, 0) 0%,
							rgba(#000, .07) 15%,
							rgba(#000, .1) 21%,
							rgba(#000, .06) 31%,
							rgba(#000, 0) 100%
						);

					@mixin screen_to, $mobile {
						content: normal;
					}
				}
			}
			&._t3 {
				&:after {
					top: -3px;
					right: -3px;
					content: '';
					transform: rotate(1deg);
					width: calc(100% + 59px);

					@mixin screen_to, $mobile {
						content: normal;
					}
				}
			}
			&._t4 {
				&:after {
					top: -5px;
					content: '';
					right: -17px;
					transform: rotate(-1deg);
					width: calc(100% + 30px);

					@mixin screen_to, $mobile {
						content: normal;
					}
				}
			}
			&._t5 {
				&:after {
					top: -3px;
					content: '';
					right: -30px;
					transform: rotate(1deg);
					width: calc(40px + 152px);

					@mixin screen_to, $mobile {
						content: normal;
					}
				}
			}
			&._t6 {
				&:after {
					top: -5px;
					content: '';
					right: -17px;
					transform: rotate(-1deg);
					width: calc(100% + 44px);

					@mixin screen_to, $mobile {
						content: normal;
					}
				}
			}
			/*#endregion [type][;]*/

			/*#region [color][:]*/
			&._c1 {
				&:after {
					background-color: $cb1v2;
				}
			}
			&._c2 {
				&:after {
					background-color: $cb2v2;
				}
			}
			&._c3 {
				&:after {
					background-color: $cb3v2;
				}
			}
			&._c4 {
				&:after {
					background-color: $cb4v2;
				}
			}
			/*#endregion [color][;]*/

		}

		/*#region [type][:]*/
		&._t1 {
			&:after {
				margin-right: -125px;
				width: calc(40px + 125px);
				
				@mixin screen_to, $mobile {
					content: '';
				}
			}
		}
		&._t2 {
			&:after {
				margin-right: -140px;
				transform: rotate(-1deg);
				width: calc(40px + 140px);
				
				@mixin screen_to, $mobile {
					content: '';
				}
			}
		}
		&._t3 {
			&:after {
				margin-right: -115px;
				width: calc(40px + 115px);
				
				@mixin screen_to, $mobile {
					content: '';
				}
			}
		}
		&._t4 {
			&:after {
				margin-right: -144px;
				transform: rotate(-1deg);
				width: calc(40px + 144px);
				
				@mixin screen_to, $mobile {
					content: '';
				}
			}
		}
		&._t5 {
			&:after {
				margin-right: -152px;
				width: calc(40px + 152px);
				
				@mixin screen_to, $mobile {
					content: '';
				}
			}
		}
		/*#endregion [type][;]*/

		/*#region [color][:]*/
		&._c1 {
			&:after {
				background-color: $cb1v2;
			}
		}
		&._c2 {
			&:after {
				background-color: $cb2v2;
			}
		}
		&._c3 {
			&:after {
				background-color: $cb3v2;
			}
		}
		&._c4 {
			&:after {
				background-color: $cb4v2;
			}
		}
		/*#endregion [color][;]*/

	}
	&_decore {
		left: 100%;
		opacity: 0;
		position: absolute;
		pointer-events: none;
		background-size: contain;
		background-repeat: no-repeat;
		will-change: opacity, transform;
		transition: all .4s ease-out-sine;

		@mixin screen_to, $mobile {
			display: none;
		}

		&._paper {
			transform: translate3d(40px, 80px, 0) scale(1.2);
		}
		&._pencil {
			transform: translate3d(0px, 60px, 0);
		}
		&._coffee {
			transform: translate3d(20px, 0px, 0) scale(1.06);
		}
		&._glasses {
			transform: translate3d(20px, 0px, 0) scale(.8);
		}
		&.aos-animate {
			opacity: 1;
			transform: none;
		}
	}

	&:hover {
		^&_box {
			&:before {
				opacity: 1;
				width: 100%;
				transform: translateX(-50%);
			}
		}
	}
	&:first-child {
		margin-top: 0px;
	}

	&.aos-animate {
		^&_box {
			filter: none;
		}
	}
}