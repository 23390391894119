.scodex {
	color: $c3;
	font: 0/0 serif;
	margin: 0px auto;
	max-width: 240px;
	overflow: hidden;
	text-align: center;
	position: relative;

	@mixin screen_to, $mobile {
		margin-top: 26px;
	}

	&:after,
	&:before {
		top: 0px;
		width: 1px;
		z-index: 10;
		content: '';
		height: 100%;
		display: block;
		background: #fff;
		position: absolute;
		box-shadow: 0px 0px 10px 5px #fff;
	}
	&:after {
		right: 0px;
	}
	&:before {
		left: 0px;
	}

	&_item {
		&_img {
			width: 182px;
			height: 182px;
			margin: 0px auto;
			position: relative;
			border-radius: 50%;
			background-size: cover;
			background-repeat: no-repeat;
			border: 16px solid currentColor;
			transition: all .4s ease-out-sine;

			&:after,
			&:before {
				top: 0px;
				left: 0px;
				content: '';
				width: 100%;
				height: 100%;
				display: block;
				position: absolute;
				border-radius: 50%;
			}
			&:before {
				background: rgba($c2, .3);
			}

			&._video {
				cursor: pointer;

				&:after {
					color: #fff;
					content: $ico_play;
					font: _font(25, 150, $ico);
				}
			}
		}
		&_name {
			color: #000;
			display: block;
			max-width: 182px;
			margin: 15px auto 0px;
			font: _font(18, 22, $f1b);

			@mixin screen_to, $mobile {
				max-width: 220px;
				margin-top: 15px;
				font: _font(14, 18, $f1b);
			}
		}
	}
	&_dots {
		margin-top: 11px;
		text-align: center;

		@mixin screen_to, $mobile {
			margin-top: 12px;
		}
	}
}