.beep {
	display: flex;
	flex-flow: wrap;
	max-width: 918px;
	padding: 0px 15px;
	margin: 40px auto 14px;
	justify-content: space-between;

	@mixin screen_to, $mobile {
		display: block;
		max-width: 320px;
		text-align: center;
		margin: -21px auto 20px;
	}

	&:after {
		width: 100%;
		content: '';
		display: table;
	}

	p,
	ul {
		margin: 0px;
	}
	li {
		margin: 0px;

		&:before {
			content: normal;
		}
	}

	&_btn {
		float: right;

		@mixin screen_to, $mobile {
			color: $c1;
			float: none;
			height: auto;
			padding: 0px;
			margin-top: 9px;
			background: none;
			border-radius: 0px;
			text-transform: none;
			font: _font(14, 18, $f1);
			border-bottom: 1px solid $c1;
		}

		&:hover {
			@mixin screen_to, $mobile {
				color: $c2;
				box-shadow: none;
			}
		}
	}
	&_info {
		min-width: 260px;
		margin: 0px 10px;

		&_label {
			display: block;
			margin-bottom: -2px;

			@mixin screen_to, $mobile {
				color: $c4;
				margin-bottom: 0px;
			}
		}
	}
	&_list {
		@mixin screen_to, $mobile {
			margin-top: 11px;
		}

		&:before {
			width: 100%;
			content: '';
			display: table;
		}

		ul {
			font-size: 18px;
		}

		&_label {
			color: $c4;
			display: block;
			font-size: 14px;
			margin-top: 4px;
			
			@mixin screen_to, $mobile {
				margin: 0px;
			}
		}
	}
}