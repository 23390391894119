.ljobs {
	top: 10px;
	z-index: 10;
	right: -78px;
	width: 270px;
	font: 0/0 serif;
	padding-top: 20px;
	text-align: center;
	position: absolute;
	transition:
		opacity .2s ease-out-sine,
		visibility .2s ease-out-sine;

	@mixin screen_to, $mobile {
		top: 0px;
		right: 0px;
		width: auto;
		padding: 0px;
		margin-top: 26px;
		position: relative;
	}

	&_btn {
		z-index: 30;
	}
	&_body {
		position: relative;
		padding-bottom: 24px;

		@mixin screen_to, $mobile {
			padding: 0px;
		}

		&:after,
		&:before {
			content: '';
			height: 14px;
			display: block;
			background: #fff;

			@mixin screen_to, $mobile {
				content: normal;
			}
		}
		&:after {
			top: 0px;
			left: 0px;
			width: 100%;
			z-index: 20;
			position: absolute;
		}
		&:before {
			box-shadow: 0px 6px 18px rgba(#000, .2);
		}
	}
	&_cell {
		background: #fff;
		position: relative;

		&:after,
		&:before {
			top: 0px;
			background: #fff;
			position: absolute;
			pointer-events: none;
			box-shadow: 0px 6px 18px rgba(#000, .2);
		}

		&_foot {
			height: 24px;
			background: #fff;
			position: relative;
			box-shadow: 0px 6px 18px rgba(#000, .2);

			@mixin screen_to, $mobile {
				height: auto;
				background: none;
				margin-top: 20px;
				box-shadow: none;
			}

			&:after {
				top: 0px;
				left: 0px;
				z-index: 15;
				content: '';
				width: 100%;
				height: 100%;
				background: #fff;
				position: absolute;

				@mixin screen_to, $mobile {
					content: normal;
				}
			}
		}

		&._1 {
			&:after {
				top: 100%;
				right: 0px;
				z-index: 10;
				content: '';
				width: 30px;
				height: 30px;
				box-shadow: none;

				@mixin screen_to, $mobile {
					content: normal;
				}
			}

			&:last-child {
				&:after {
					content: normal;
				}
			}
		}
		&._2 {
			margin: 0px 30px 0px -30px;

			@mixin screen_to, $mobile {
				margin: 0px;
			}

			&:after,
			&:before {
				content: '';

				@mixin screen_to, $mobile {
					content: normal;
				}
			}
			&:after {
				top: 100%;
				left: 0px;
				z-index: 10;
				width: 42px;
				height: 30px;
				box-shadow: none;
			}
			&:before {
				top: 0px;
				width: 100%;
				right: -30px;
				height: calc(100% + 30px);
			}

			&:last-child {
				&:after {
					content: normal;
				}
				&:before {
					height: calc(100% - 30px);
				}
			}
		}
		&._3 {
			margin: 0px -12px 0px 12px;

			@mixin screen_to, $mobile {
				margin: 0px;
			}

			&:after,
			&:before {
				top: 0px;
				right: 100%;
				content: '';

				@mixin screen_to, $mobile {
					content: normal;
				}
			}
			&:after {
				width: 42px;
				height: 73px;
			}
			&:before {
				width: 12px;
				height: 126px;
			}
		}
		& + & {
			@mixin screen_to, $mobile {
				margin-top: 10px;
			}
		}
	}
	&_item {
		color: $c5;
		z-index: 10;
		border: none;
		display: block;
		min-height: 112px;
		background: #fff;
		padding: 21px 30px;
		position: relative;
		font: _font(14, 18, $f1);
		box-shadow: 0px 6px 18px rgba(#000, .2);

		@mixin screen_to, $mobile {
			min-height: auto;
			box-shadow: none;
			background: #f4f7fa;
			padding: 25px 30px 16px;
		}

		&_name {
			color: $c1;
			opacity: 0;
			vertical-align: top;
			background: $gSelection;
			font: _font(18, 22, $f1b);
			transition: all .4s ease-out-sine;
			background-size: 200%;

			@mixin screen_to, $mobile {
				opacity: 1;
				background: none;
			}
		}
		&_text,
		&_city {
			opacity: 0;
			transition: all .4s ease-out-sine;
			transform: translateY(10px) scale(.95);

			@mixin screen_to, $mobile {
				opacity: 1;
				transform: none;
			}
		}
		&_text {
			display: block;
			margin-top: 10px;
		}
		&_city {
			display: block;
			transition-delay: .2s;
		}

		&:hover {
			color: $c5;

			^&_name {
				color: #000;
				background-position: -100% 0%;
			}
		}
	}
	&_title {
		z-index: 30;
		width: 178px;
		height: 38px;
		border: none;
		color: #fff;
		display: block;
		position: relative;
		margin: -20px auto -17px;
		transform: translateZ(0);
		font: _font(16, 42, $f1b);
		text-transform: uppercase;

		@mixin screen_to, $mobile {
			margin: 0px auto 20px;
		}

		&:after,
		&:before {
			top: 0px;
			left: 0px;
			width: 100%;
			opacity: .6;
			z-index: -1;
			content: '';
			height: 38px;
			display: block;
			position: absolute;
			transform: rotate(-1deg);
			transform-origin: 100% 0%;
			transition: all .4s ease-in-out-sine;
		}
		&:after {
			background-color: $cb1v2;
			background-image:
				linear-gradient(-20deg,
					rgba(#000, 0) 0%,
					rgba(#000, .07) 15%,
					rgba(#000, .1) 21%,
					rgba(#000, .06) 31%,
					rgba(#000, 0) 100%
				);
		}

		&:hover {
			color: #000;
		}
	}

	.is_clean & {
		opacity: 0;
		visibility: hidden;
	}
	&.aos-animate {
		^&_item {
			&_name,
			&_text,
			&_city {
				opacity: 1;
				transform: none;
			}
		}
	}
}