a {
	color: $c1;
	border: none;
	outline: none;
	display: inline;
	position: relative;
	text-decoration: none;
	border-bottom: 1px solid transparent;
	transition: color .2s ease-out-sine,
				border .2s ease-out-sine;

	&:hover {
		border-color: currentColor;
	}
	&[href^='tel:'] {
		vertical-align: top;
		display: inline-block;
	}
}