.lbody {
	display: flex;
	overflow: hidden;
	min-height: 100%;
	flex-flow: column;
	position: relative;
	padding-top: 145px;

	@mixin screen_to, $mobile {
		padding-top: 50px;
	}

	.index & {
		padding-top: 85px;

		@mixin screen_to, $mobile {
			padding-top: 50px;
		}
	}
}

.lmain {
	flex-grow: 1;
	flex-shrink: 0;
}

.lwrap {
	max-width: 908px;
	margin: 60px auto;
	padding: 0px 20px;

	@mixin screen_to, $mobile {
		margin: 40px auto;
		padding: 0px 15px;
		max-width: $mwmbl;
	}

	&_box {
		margin: 0px auto;
		max-width: 754px;

		&:last-child,
		& > :last-child {
			margin-bottom: 0px;
		}
		&:first-child,
		& > :first-child {
			margin-top: 0px;
		}
	}

	&._s2 {
		max-width: 794px;

		@mixin screen_to, $mobile {
			max-width: $mwmbl;
		}
	}
	&:last-child,
	& > :last-child {
		margin-bottom: 0px;
	}
	&:first-child,
	& > :first-child {
		margin-top: 0px;
	}
}

.lhide {
	display: none;
}

.ltitle {
	max-width: 988px;
	margin: 39px auto;
	padding: 0px 20px;
	text-align: center;

	& + .lwrap {
		margin-top: 0px;
	}
}





.lhead {
	display: flex;
	flex-flow: wrap;
	max-width: 1134px;
	padding: 0px 20px;
	text-align: center;
	margin: 20px auto 30px;
	justify-content: flex-end;
	
	@mixin screen_to, $mobile {
		margin-top: 26px;
		padding: 0px 10px;
		max-width: $mwmbl;
		justify-content: center;
	}

	&_btn {
		order: 1;
		
		@mixin screen_to, $mobile {
			order: 2;
			margin-top: 14px;
		}
	}
	&_title {
		order: 2;
		width: 100%;
		margin: 8px 0px 0px;
		
		@mixin screen_to, $mobile {
			order: 1;
		}

		&:first-child {
		}
	}

	& + .lwrap {
		margin-top: 0px;
	}
}





