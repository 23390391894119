.lpage {
	overflow: hidden;
	max-width: 1920px;
	padding: 30px 0px;
	position: relative;
	transform: translateZ(0);
	margin: -21px auto -30px;
	will-change: filter, transform;
	transition: filter .6s ease-out-sine;

	@mixin screen_to, $mobile {
		margin: 0px;
		padding: 20px 0px 0px;
	}

	&_box {
		margin: 0px auto;
		max-width: 1182px;
		position: relative;
		padding: 30px 98px 0px 20px;

		@mixin screen_to, $mobile {
			padding: 0px;
			margin: 0px auto;
			max-width: $mwmbl;
		}

		&:after,
		&:before {
			z-index: 1;
			content: '';
			height: 60px;
			display: block;
			background: #fff;
			position: absolute;
			pointer-events: none;
			transform-origin: 0px;
			width: calc(100% / (1182 / 840));
			box-shadow: -3px 3px 10px 2px rgba(#000, .2);

			@mixin screen_to, $mobile {
				content: normal;
			}
		}
		&:after {
			top: 0px;
			left: 94px;
			transform: rotate(3deg);
		}
		&:before {
			top: 12px;
			left: 66px;
			transform: rotate(.5deg);
		}
	}
	&_wrap {
		z-index: 2;
		padding: 60px 0px;
		max-width: 1042px;
		background: #fff;
		position: relative;
		box-shadow: -6px 6px 18px rgba(#000, .2);

		@mixin screen_to, $mobile {
			box-shadow: none;
			padding: 0px 10px;
		}
	}
	&_head {
		padding: 0px 242px 0px 20px;

		@mixin screen_to, $mobile {
			padding: 0px;
		}

		&:after {
			width: 100%;
			content: '';
			display: table;
		}

		&_box {
			width: 100%;
			float: right;
			display: flex;
			flex-flow: wrap;
			max-width: 704px;
			position: relative;
			padding-left: 240px;
			align-items: flex-start;
			justify-content: center;

			@mixin screen_to, $mobile {
				float: none;
				padding: 0px;
				justify-content: flex-start;
			}
		}
		&_title {
			width: 100%;
			max-width: 384px;
			margin: -6px 0px 31px;
			font: _font(36, 44, $f1b);
		
			@mixin screen_to, $mobile {
				font: 24px/1 $f1b;
				margin: 6px 0px 0px;
				width: calc(100% - 106px);
			}
		}
	}
	&_decore {
		right: 100%;
		position: absolute;
		background-size: contain;
		background-repeat: no-repeat;

		@mixin screen_to, $mobile {
			display: none;
		}
	}

	.is_clean & {
		filter: url('data:image/svg+xml;utf8,&lt;svg xmlns=\"http://www.w3.org/2000/svg\"&gt;&lt;filter id=\"grayscale\"&gt;&lt;feColorMatrix type=\"matrix\" values=\"0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\"/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale'); /* Firefox 10+, Firefox on Android */
		filter: grayscale(100%);
	}
}