@define-mixin screen_to, $size {
	@media screen and (max-width: $size) {
		@mixin-content;
	}
}

@define-mixin screen_from, $size {
	@media screen and (min-width: calc($size + 1px)) {
		@mixin-content;
	}
}

@define-mixin screen_between, $size_a, $size_b {
	@media screen and (min-width: $size_a) and (max-width: $size_b) {
		@mixin-content;
	}
}

@define-mixin font_face, $name {
	@font-face {
		font-family: $name;
		@if $font_package == 'short' {
			src: url('../fonts/$name.woff2') format('woff2'),
				 url('../fonts/$name.woff') format('woff');
		} @else {
			src: url('../fonts/$name.eot');
			src: url('../fonts/$name.eot?#iefix') format('embedded-opentype'),
				 url('../fonts/$name.woff2') format('woff2'),
				 url('../fonts/$name.woff') format('woff'),
				 url('../fonts/$name.ttf') format('truetype'),
				 url('../fonts/$name.svg#$name') format('svg');
		}
		font-style: normal;
		font-weight: normal;
		font-stretch: normal;
	}
}