img {
	height: auto;
	display: block;
	max-width: 100%;

	@mixin screen_to, $mobile {
		float: none;
	}

	&._flft,
	&[align='left'] {
		margin-right: 20px
	}
	&._frgt,
	&[align='right'] {
		margin-left: 20px;
	}
	&._cntr,
	&[align='middle'] {
		margin: 0px auto;
	}
}
video {
	top: 0px;
	left: 0px;
	z-index: 1;
	width: 100%;
	height: 100%;
	position: absolute;
}
figure {
	padding: 0px;
	display: block;
	margin: 40px auto;

	@mixin screen_to, $mobile {
		margin: 40px -20px;
	}

	img {
		width: 100%;
	}

	&:last-child {
		margin-bottom: 0px;
	}
	&:first-child {
		margin-top: 0px;
	}
}
figcaption {
	padding: 0px;
	display: block;
	margin: 17px 0px -5px;

	@mixin screen_to, $mobile {
		padding: 0px 20px;
	}
}