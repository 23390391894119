.quote {
	max-width: 988px;
	margin: 60px auto;
	padding: 3px 20px 0px;

	@mixin screen_to, $mobile {
		background: none;
		margin: 40px auto;
		max-width: $mwmbl;
		text-align: center;
		padding: 0px 10px 0px;
	}

	&:after {
		height: 2px;
		content: '';
		display: block;
		background: $c3;
		margin-top: 32px;
	}

	&_txt {
		display: block;
		margin: 0px auto;
		max-width: 754px;
		position: relative;
		font: _font(30, 36, $f1);

		@mixin screen_to, $mobile {
			font: _font(18, 22, $f1);
		}

		&:after {
			top: -3px;
			left: -98px;
			content: '';
			opacity: .6;
			z-index: -1;
			width: 178px;
			height: 38px;
			display: block;
			position: absolute;
			pointer-events: none;
			transform: rotate(-1deg);
			background-color: $cb1v2;
			transform-origin: 100% 0%;
			background-image:
				linear-gradient(-90deg,
					rgba(#000, 0) 0%,
					rgba(#000, .1) 85%,
					rgba(#000, .1) 81%,
					rgba(#000, .1) 71%,
					rgba(#000, 0) 100%
				);
		}
	}

	&:last-child {
		margin-bottom: 0px;
	}
	&:first-child {
		margin-top: 0px;
	}
}