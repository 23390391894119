.pluses {
	max-width: 1094px;
	margin: 60px auto;
	padding: 0px 20px;

	@mixin screen_to, $mobile {
		padding: 0px 10px;
		max-width: $mwmbl;
		margin: 40px auto 35px;
	}

	&:after {
		width: 100%;
		content: '';
		display: table;
	}

	&_wrap {
		flex-flow: wrap;
		justify-content: space-between;

		@mixin screen_to, $mobile {
			flex-flow: nowrap;
		}
	}
	&_item {
		width: 280px;
		text-align: center;

		@mixin screen_to, $mobile {
			width: 100%;
			margin: 0px;
		}

		&_img {
			margin: 0px auto;
			max-width: 174px;
		}
		&_txt {
			display: block;
			margin: 15px auto 0px;
			font: _font(22, 26, $f1);
			
			@mixin screen_to, $mobile {
				max-width: 280px;
				font: _font(18, 22, $f1);
			}
		}
	}
	&_dots {
		display: none;
		margin-top: 11px;

		@mixin screen_to, $mobile {
			display: block;
		}
	}
	&_prev,
	&_next {
		top: 30px;
		color: $c1;
		z-index: 15;
		width: 26px;
		height: 20px;
		display: none;
		overflow: hidden;
		font: 10px/1 $ico;
		position: absolute;
		transition: all .2s ease-out-sine;

		@mixin screen_to, $mobile {
			display: block;
		}

		&:after {
			top: 5px;
			display: block;
			position: absolute;
		}

		&.swiper-button-disabled {
			opacity: 0;
			visibility: hidden;
		}
	}
	&_prev {
		left: 10px;

		&:after {
			left: 0px;
			content: $ico_arrow_l1;
		}
	}
	&_next {
		right: 10px;

		&:after {
			right: 0px;
			content: $ico_arrow_r1;
		}
	}

	&:last-child {
		margin-bottom: 0px;
	}
	&:first-child {
		margin-top: 0px;
	}
}