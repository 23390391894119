.lheader {
	top: 0px;
	left: 0px;
	width: 100%;
	z-index: 500;
	font: 0/0 serif;
	user-select: none;
	position: absolute;
	border-bottom: 1px solid $c3;

	@mixin screen_to, $mobile {
		height: 50px;
		position: fixed;
		background: #f4f7fa;
	}

	&_boxm {
		height: 0px;
		margin: 0px auto;
		max-width: 988px;
		position: relative;

		@mixin screen_to, $mobile {
			padding: 0px 15px;
			max-width: $mwmbl;
		}
	}
	&_boxl {
		height: 0px;
		margin: 0px auto;
		max-width: 1140px;
		position: relative;

		@mixin screen_to, $mobile {
			height: 100%;
			max-width: $mwmbl;
		}

		&_bar {
			top: 41px;
			right: 20px;
			z-index: 125;
			position: absolute;
	
			@mixin screen_to, $mobile {
				top: 0px;
				opacity: 0;
				right: 0px;
				width: 100%;
				height: 100%;
				padding: 0px 15px;
				visibility: hidden;
				background: #f4f7fa;
				transform: translateX(-100%);
				transition: all .2s ease-out-sine;
			}
		}
	}
	&_logo {
		top: 8px;
		left: 20px;
		color: $c2;
		z-index: 10;
		width: 116px;
		border: none;
		display: block;
		position: absolute;
		transition: color .2s ease-out-sine;

		@mixin screen_to, $mobile {
			top: 16px;
			left: 15px;
			width: auto;
		}

		&:after,
		&:before {
			display: block;
		}
		&:after {
			float: right;
			color: #000;
			font: 18px/18px $ico;
			content: $ico_logo_ru;
		}
		&:before {
			float: left;
			font: 60px/1 $ico;
			content: $ico_logo;

			@mixin screen_to, $mobile {
				display: none;
			}
		}

		&._en {
			&:after {
				font-size: 16px;
				margin-top: -4px;
				content: $ico_logo_en;
			}
		}
		&[href] {
			&:hover {
				color: $c1;
			}
		}
	}
	&_beep {
		color: $c4;
		margin-top: -22px;
		vertical-align: top;
		display: inline-block;
		font: _font(14, 18, $f1);

		@mixin screen_to, $mobile {
			display: none;
		}

		&_lbl {
			display: block;
		}
		&_tel {
			border: none;
			color: #000;
			display: block;
			margin-top: 1px;
			font: _font(16, 20, $f1b);
		}
		&_call {
			border: none;
			display: block;
			font-family: $f1b;

			&:before {
				margin-right: 4px;
				vertical-align: top;
				content: $ico_phone;
				display: inline-block;
				font: _font(12, 18, $ico);
			}

			&:hover {
				color: $c2;
			}
		}
	}

	&-fix {
		position: fixed;
		background: #f4f7fa;
		transform: translateY(-100%);

		^&_boxl {
			&_bar {
				top: 31px;
			}
		}
		^&_logo {
			width: 97px;

			&:after {
				font-size: 16px;
				line-height: 16px;
			}
			&:before {
				font-size: 50px;
			}

			&._en {
				&:after {
					font-size: 14px;
				}
			}
		}
		^&_beep {
			margin-top: 0px;

			&_lbl,
			&_tel {
				display: none;
			}
			&_call {
				&:before {
					line-height: 1;
					font-size: 18px;
				}
			}
		}

		&-anim {
			transition: transform .4s ease-out-sine;
		}
		&-show {
			transform: none;
		}
	}
	&-menu {
		^&_boxl {
			&_bar {
				opacity: 1;
				transform: none;
				visibility: visible;
			}
		}
	}
	&-home {
		border-color: transparent;

		^&_logo {
			@mixin screen_from, $mobile {
				opacity: 0;
				visibility: hidden;
			}
		}

		&^&-fix {
			border-color: $c3;

			^^&_logo {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}