.btn,
button {
	margin: 0px;
	padding: 0px;
	border: none;
	outline: none;
	max-width: 100%;
	cursor: pointer;
	overflow: hidden;
	background: none;
	box-shadow: none;
	user-select: none;
	text-shadow: none;
	position: relative;
	border-radius: 0px;
	text-align: center;
	vertical-align: top;
	display: inline-block;
	transform: translateZ(0);
	transition: all .4s ease-out-sine;

	&:after,
	&:before {
		pointer-events: none;
	}
}
.btn {
	&_1 {
		color: #fff;
		background: $c2;
		height: em(48, 14);
		padding: 0px em(30, 14);
		font: _font(14, 48, $f1b);
		border-radius: em(25, 14);
		text-transform: uppercase;

		&:hover {
			color: #fff;
			background: $c1;
			box-shadow: 0px 2px 8px rgba($c2, .4);
		}
	}
	&_2 {
		color: $c1;
		height: em(48, 14);
		background: #fff;
		border: 2px dotted $c1;
		padding: 0px em(30, 14);
		font: _font(14, 44, $f1b);
		border-radius: em(25, 14);
		text-transform: uppercase;

		&:hover {
			color: #fff;
			background: $c1;
			border-color: $c1;
			box-shadow: 0px 2px 8px rgba($c2, .4);
		}
	}
	&_3 {
		color: $c1;
		height: 30px;
		padding: 0px 15px;
		font: 16px/28px $f1b;
		background: #eaeaea;

		&:after {
			top: 0px;
			left: 0px;
			width: 0px;
			z-index: -1;
			content: '';
			height: 100%;
			display: block;
			position: absolute;
			background: $chover;
			transition: all .2s ease-in-out-sine;
		}

		&:hover {
			color: #000;

			&:after {
				width: 100%;
			}
		}
	}
	&_4 {
		color: #fff;
		background: $c1;
		height: em(40, 14);
		padding: 0px em(20, 14);
		text-transform: uppercase;
		font: _font(14, 40, $f1b);
		border-radius: em(20, 14);

		&:hover {
			color: #fff;
			background: $c2;
		}
	}
	&_5 {
		color: #fff;
		background: $c7;
		height: em(40, 14);
		padding: 0px em(20, 14);
		text-transform: uppercase;
		font: _font(14, 40, $f1b);
		border-radius: em(20, 14);

		&:hover {
			color: #fff;
			background: $c1;
		}
	}

	&_arw {
		color: $c3;
		width: 70px;
		height: 20px;

		@mixin screen_to, $mobile {
			width: 50px;
		}

		&:after {
			top: 0px;
			right: 0px;
			display: block;
			font: 20px/1 $ico;
			position: absolute;
			content: $ico_arrow_r1;
			transform: translateX(-10px);
			transition: transform .2s ease-out-sine;

			@mixin screen_to, $mobile {
				transform: none;
			}
		}
 
		&:hover,
		a:hover & {
			color: $c1;

			&:after {
				transform: none;
			}
		}
	}
}