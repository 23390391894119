.pvideo {
	max-width: 754px;
	position: relative;
	background: #fff;
	box-shadow: 0px 6px 18px rgba(#000, .05);

	iframe {
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		position: absolute;
	}

	&_box {
		background: $c3;
		position: relative;

		&:after {
			content: '';
			display: block;
			position: relative;
			padding-bottom: 56.25%;
		}
	}
	&_close {
		top: -15px;
		color: $c4;
		width: 30px;
		right: -15px;
		height: 30px;
		z-index: 100;
		background: #fff;
		position: absolute;
		border-radius: 50%;

		&:after,
		&:before {
			top: 50%;
			left: 50%;
			content: '';
			display: block;
			position: absolute;
			transform: rotate(45deg);
			background: currentColor;
		}
		&:after {
			width: 20px;
			height: 2px;
			margin: -1px 0px 0px -10px;
		}
		&:before {
			width: 2px;
			height: 20px;
			margin: -10px 0px 0px -1px;
		}

		&:hover {
			color: $c1;
		}
	}
}