.reviews {
	max-width: 988px;
	margin: 0px auto;
	padding: 0px 20px;

	@mixin screen_to, $mobile {
		padding: 0px 10px;
		max-width: $mwmbl;
	}

	&_item {
		color: $cb1;
		display: flex;
		flex-flow: wrap;
		position: relative;

		@mixin screen_to, $mobile {
			padding: 0px;
			display: block;
		}

		iframe {
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			position: absolute;
		}

		&_img {
			top: 0px;
			left: 0px;
			width: 60px;
			height: 60px;
			position: absolute;
			border-radius: 50%;
			background: $c3 no-repeat;
			background-size: cover;
		}
		&_btn {
			color: $c3;
			right: 30px;
			height: 20px;
			bottom: 25px;
			position: absolute;
			padding-left: 41px;
			font: _font(14, 20, $f2i);

			@mixin screen_to, $mobile {
				right: 15px;
				bottom: 21px;
			}

			&:after {
				top: 0px;
				left: -20px;
				display: block;
				position: absolute;
				content: $ico_arrow_r1;
				font: _font(10, 22, $ico);
				transform: translateX(-5px);
				transition: transform .4s ease-out-sine;

				@mixin screen_to, $mobile {
					transform: none;
				}
			}
			&:before {
				content: attr(data-show) ' ';
			}

			&:hover {
				color: $c1;

				&:after {
					transform: none;
				}
			}
		}
		&_name {
			color: #000;
			font: _font(18, 24, $f2);

			@mixin screen_to, $mobile {
				font-size: 14px;
				margin-bottom: 5px;
			}
		}
		&_user {
			color: $c5;
			width: 288px;
			min-height: 60px;
			font: _font(16, 20, $f1);
			padding: 2px 20px 0px 80px;
			
			@mixin screen_to, $mobile {
				width: auto;
				position: relative;
				margin-bottom: 36px;
				font: _font(12, 14, $f1);
				padding: 3px 0px 0px 80px;
			}

			span {
				display: block;
			}
		}
		&_info {
			flex-grow: 1;
			flex-basis: 0;
			cursor: pointer;
			font-family: $f2i;
			position: relative;
			background: #fff;
			color: currentColor;
			border-radius: 15px;
			padding: 24px 30px 43px;
			box-shadow: 0px 6px 18px rgba(#000, .05);

			@mixin screen_to, $mobile {
				padding: 21px 15px 43px;
			}

			&:after {
				left: -20px;
				bottom: 20px;
				display: block;
				cursor: default;
				font: 28px/1 $ico;
				position: absolute;
				color: currentColor;
				pointer-events: none;
				content: $ico_bubble_l1;

				@mixin screen_to, $mobile {
					top: -18px;
					left: 26px;
					bottom: auto;
				}
			}

			&:hover {
				^^&_intro {
					^^^&_dots {
						color: $c1;
					}
				}
			}
			&._active {
				cursor: default;

				^^&_btn {
					&:before {
						content: attr(data-hide) ' ';
					}
				}
				^^&_more {
					display: block;
				}
				^^&_intro {
					display: none;
				}
			}
		}
		&_view {
			flex-grow: 1;
			flex-basis: 0;
			background: $c3;
			position: relative;

			@mixin screen_to, $mobile {
				margin-top: -20px;
			}

			&:after {
				height: 0px;
				content: '';
				display: block;
				padding-bottom: 56.25%;
			}
		}
		&_more {
			color: #000;
			display: none;
		}
		&_dots {
			color: $c3;
			font: 0/0 serif;
			position: relative;
			vertical-align: top;
			display: inline-block;
			margin: 12px 0px 0px 20px;
			transition: all .4s ease-out-sine;

			@mixin screen_to, $mobile {
				margin: 10px 0px 0px 15px;
			}

			i {
				margin: 0px 10px;

				@mixin screen_to, $mobile {
					margin: 0px 8px;
				}
			}
			i,
			&:after,
			&:before {
				width: 6px;
				height: 6px;
				content: '';
				border-radius: 50%;
				vertical-align: top;
				display: inline-block;
				background: currentColor;

				@mixin screen_to, $mobile {
					width: 4px;
					height: 4px;
				}
			}

			&._load {
				color: $c1;

				i,
				&:after,
				&:before {
					animation: ball-beat .8s ease-out-sine infinite both;
				}
				&:after,
				&:before {
					animation-delay: -0.35s;
				}
			}
		}
		&_intro {
			color: #000;
			display: block;
			user-select: none;
		}

		&._2 {
			color: $cb3;
		}
		&._3 {
			color: $cb2;
		}
		&._4 {
			color: $cb4;
		}
		& + & {
			margin-top: 20px;
		}
	}
}