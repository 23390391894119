.smain {
	top: 0px;
	left: 0px;
	width: 220px;
	font: 0/0 serif;
	position: absolute;
	transition:
		opacity .2s ease-out-sine,
		visibility .2s ease-out-sine;

	@mixin screen_to, $mobile {
		width: 86px;
		position: relative;
		margin-right: 20px;
	}

	&_box {
		border-radius: 50%;
	}
	&_item {
		width: 100%;
		background-size: cover;
		background-repeat: no-repeat;

		&:before {
			content: '';
			display: block;
			padding-bottom: 100%;
		}
	}
	&_dots {
		text-align: center;
		margin: 26px 0px -4px;

		@mixin screen_to, $mobile {
			margin-top: 9px;
		}
	}

	.is_clean & {
		opacity: 0;
		visibility: hidden;
	}
}