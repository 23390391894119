*,
*:after,
*:before {
	outline: none;
	box-sizing: inherit;
	-webkit-print-color-adjust:exact;
}

*::selection {
	color: #fff;
	background: $c1;
}

html,
body {
	margin: 0px;
	height: 100%;
	padding: 0px;
}
html {
	font-size: 62.5%;
	box-sizing: border-box;
}
body {
	color: #000;
	position: relative;
	background: #f4f7fa;
	text-size-adjust: none;
	font: _font($base_sz, $base_lh, $base_fn);
	
	@mixin screen_to, $mobile {
		font: _font($base_sz_m, $base_lh_m, $base_fn);
	}

	&.is_clean {
		overflow: hidden;
		pointer-events: none;
	}
}
.index {
	@mixin screen_to, $mobile {
		background: #fff;
	}
}
.inner {
	&._white {
		background: #fff;
	}
}

/*#region [tags][:]*/
img,
figure,
blockquote {
	&:last-child {
		margin-bottom: 0px;
	}
}
iframe {
	border: none;
}
/*#endregion [tags][;]*/