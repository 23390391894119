.cols {
	display: flex;
	flex-flow: wrap;
	max-width: 988px;
	margin: 60px auto;

	@mixin screen_to, $mobile {
		margin: 40px auto;
		padding: 0px 16px;
		max-width: $mwmbl;
	}

	&_foot {
		width: 100%;
		text-align: right;
		margin: 14px 20px 0px;

		@mixin screen_to, $mobile {
			text-align: center;
			margin: 16px 0px 0px;
		}
	}
	&_item {
		margin: 0px 20px;
		width: calc(50% - 40px);

		@mixin screen_to, $mobile {
			width: 100%;
			margin: 0px;
		}

		& + & {
			@mixin screen_to, $mobile {
				margin-top: calc($p_mn_m - ($p_cl_m + $p_bl_m));
			}
		}
	}

	&:last-child {
		margin-bottom: 0px;
	}
	&:first-child {
		margin-top: 0px;
	}
}