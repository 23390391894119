.lfeatures {
	display: flex;
	flex-flow: wrap;
	margin: 0px 0px 30px;

	@mixin screen_to, $mobile {
		display: block;
	}

	&_menu {
		margin: 0px;
		flex-grow: 1;
		flex-shrink: 0;
		max-width: 560px;
		user-select: none;
		margin-right: 20px;

		@mixin screen_to, $mobile {
			margin: 0px;
		}

		&.aos-animate {
			li {
				opacity: 1;
				transform: none;
			}
		}
		li {
			opacity: 0;
			transform-origin: 0% 50%;
			transition: all .4s ease-out-sine;
			transform: translate(-40px, 0px) scale(.6);

			@mixin screen_to, $mobile {
				opacity: 1;
				transform: none;
				transition: none;
			}

			&:before {
				content: normal;
			}

			& + li {
				margin-top: 4px;
			}
			&:nth-child(2) {transition-delay: .1s;}
			&:nth-child(3) {transition-delay: .2s;}
			&:nth-child(4) {transition-delay: .3s;}
			&:nth-child(5) {transition-delay: .4s;}
			&:nth-child(6) {transition-delay: .5s;}
			&:nth-child(7) {transition-delay: .6s;}
			&:nth-child(8) {transition-delay: .8s;}
		}
		&_link {
			color: $c4;
			cursor: pointer;
			padding: 0px 20px;
			position: relative;
			vertical-align: top;
			display: inline-block;
			font: _font(24, 36, $f1);
			transition: all .4s ease-in-out-sine;
			background-size: 200%;

			@mixin screen_to, $mobile {
				display: block;
				padding: 7px 10px;
				font: _font(18, 22, $f1);
			}

			&:after {
				top: 50%;
				color: $c3;
				opacity: 0;
				right: 100%;
				width: 52px;
				height: 20px;
				overflow: hidden;
				font: 20px/1 $ico;
				text-indent: -48px;
				position: absolute;
				white-space: nowrap;
				pointer-events: none;
				content: $ico_arrow_r1;
				margin: -10px 20px 0px 0px;
				transform: translateX(-10px);
				transition: all .4s ease-in-out-sine;

				@mixin screen_to, $mobile {
					content: normal;
				}
			}

			&._show {
				opacity: 1;
				transform: none;
				visibility: visible;
				pointer-events: auto;
			}
			&:hover,
			&._active {
				color: #fff;
				background-position: -100% 0%;

				&:after {
					opacity: 1;
					transform: none;
				}
			}
			&._active {
				cursor: default;

				&:after {
					color: #000;
				}
			}
		}
	}
	&_data {
		flex-grow: 1;
		flex-basis: 0;
	}
	&_item {
		display: none;
		text-align: center;

		@mixin screen_to, $mobile {
			margin: 0px auto;
			max-width: 320px;
			padding: 20px 0px 22px;
		}

		&_img {
			width: auto;
			height: 94px;
			margin: 0px auto 27px;

			@mixin screen_to, $mobile {
				height: 60px;
				margin-bottom: 7px;
			}
		}
		&_num {
			display: block;
			font: 72px/1 $f1b;
			margin: -15px 0px 14px;

			@mixin screen_to, $mobile {
				font-size: 48px;
				margin: -10px 0px 2px;
			}
		}
		&_text {
			margin-top: -2px;
			font: _font($base_sz, 22, $base_fn);
		}

		&._active {
			display: block;
		}
	}

	/*#region [colors][:]*/
	&-c1 {
		^&_menu {
			&_link {
				background-image: linear-gradient(to right, $c3 50%, $cb1v2 50%);
			}
		}
		^&_item {
			&_num {
				color: $cb1v2;
			}
		}
	}
	&-c2 {
		^&_menu {
			&_link {
				background-image: linear-gradient(to right, $c3 50%, $cb2v2 50%);
			}
		}
		^&_item {
			&_num {
				color: $cb2v2;
			}
		}
	}
	&-c3 {
		^&_menu {
			&_link {
				background-image: linear-gradient(to right, $c3 50%, $cb3v2 50%);
			}
		}
		^&_item {
			&_num {
				color: $cb3v2;
			}
		}
	}
	&-c4 {
		^&_menu {
			&_link {
				background-image: linear-gradient(to right, $c3 50%, $cb4v2 50%);
			}
		}
		^&_item {
			&_num {
				color: $cb4v2;
			}
		}
	}
	/*#endregion [colors][;]*/

	&:last-child {
		margin-bottom: 0px;
	}
}