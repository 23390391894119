.uimage {
	font: 0/0 serif;
	overflow: hidden;
	max-width: 908px;
	margin: 60px auto;
	padding: 0px 20px;

	@mixin screen_to, $mobile {
		margin: 40px auto;
		padding: 0px 15px;
		max-width: $mwmbl;
	}

	&_src {
		width: auto;
		margin: 0px auto;
	}
	&_txt {
		color: $c5;
		padding: 0px;
		display: block;
		max-width: 600px;
		text-align: center;
		margin: 20px auto 0px;
		font: _font(14, 18, $f1);

		@mixin screen_to, $mobile {
			font: _font(12, 16, $f1);
		}

		&:before {
			height: 1px;
			content: '';
			display: block;
			background: $c3;
			margin-bottom: 12px;

			@mixin screen_to, $mobile {
				margin-bottom: 10px;
			}
		}
	}
}