.mlang {
	color: $c1;
	cursor: pointer;
	margin-left: 25px;
	padding-left: 18px;
	position: relative;
	vertical-align: top;
	display: inline-block;
	transition: all .2s ease-out-sine;

	@mixin screen_to, $mobile {
		margin: 16px 0px 0px;
	}

	&:before {
		top: 0px;
		left: 0px;
		z-index: 30;
		font: 18px/1 $ico;
		position: absolute;
		border-radius: 50%;
		color: currentColor;
		content: $ico_lang;
	}

	&_list {
		top: -4px;
		opacity: 0;
		z-index: 15;
		right: 100%;
		height: 26px;
		overflow: hidden;
		background: #fff;
		position: absolute;
		visibility: hidden;
		text-align: center;
		border-radius: 26px;
		white-space: nowrap;
		margin-right: -22px;
		pointer-events: none;
		transform: scale(.85);
		padding: 0px 27px 0px 5px;
		transform-origin: 100% 50%;
		transition: all .2s ease-out-sine;
		box-shadow: 0px 4px 8px rgba(#000, .1);

		@mixin screen_to, $mobile {
			left: -4px;
			right: auto;
			transform-origin: 0% 50%;
			padding: 0px 5px 0px 27px;
		}
	}
	&_link {
		border: none;
		color: #000;
		display: block;
		padding: 0px 5px;
		background: #fff;
		vertical-align: top;
		display: inline-block;
		font: _font(14, 26, $f1);
		transition: all .2s ease-out-sine;

		&:hover {
			color: $c1;
		}
		&:last-child {
			&:after {
				content: normal;
			}
		}
	}
	&_value {
		display: block;
		padding-left: 10px;
		color: currentColor;
		font: _font(14, 18, $f1);

		&:after {
			width: 0px;
			height: 0px;
			border-style: solid;
			vertical-align: top;
			display: inline-block;
			margin: 7px 0px 0px 5px;
			border-width: 4px 2.5px 0px 2.5px;
			border-color: #667ca0 transparent transparent transparent;

			@mixin screen_to, $mobile {
				content: '';
			}
		}
	}

	&:hover {
		color: $c2;

		^&_list {
			opacity: 1;
			transform: none;
			visibility: visible;
			pointer-events: auto;
		}
	}
}