.pmain {
	position: relative;
	background: #fff;
	border-radius: 20px;
	padding: 53px 40px 40px;
	box-shadow: 0px 6px 18px rgba(#000, .05);

	@mixin screen_to, $mobile {
		border-radius: 0px;
		padding: 34px 15px 30px;
	}

	/*#region [object][:]*/
	&_ok {
		width: 100px;
		display: block;
		margin: calc(30px - $p_bl) auto 0px;

		@mixin screen_to, $mobile {
			margin-top: calc(30px - $p_bl_m);
		}
	}
	&_close {
		top: 18px;
		color: $c4;
		right: 18px;
		width: 20px;
		height: 20px;
		position: absolute;

		@mixin screen_to, $mobile {
			top: 13px;
			right: 13px;
		}

		&:after,
		&:before {
			top: 50%;
			left: 50%;
			content: '';
			display: block;
			position: absolute;
			transform: rotate(45deg);
			background: currentColor;
		}
		&:after {
			width: 20px;
			height: 2px;
			margin: -1px 0px 0px -10px;
		}
		&:before {
			width: 2px;
			height: 20px;
			margin: -10px 0px 0px -1px;
		}

		&:hover {
			color: $c1;
		}
	}
	&_title {
		display: block;
		text-align: center;
		margin-bottom: 34px;
		font: _font(30, 34, $f1b);

		@mixin screen_to, $mobile {
			margin-bottom: 10px;
			font: _font(24, 28, $f1b);
		}
	}
	/*#endregion [object][;]*/

	&-msg {
		max-width: 468px;
		text-align: center;
	}
	&-form {
		max-width: 948px;

		@mixin screen_to, $mobile {
			max-width: $mwmbl;
		}
	}
	&-review {
		max-width: 754px;

		@mixin screen_to, $mobile {
			max-width: $mwmbl;
		}
	}
}