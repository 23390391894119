
.swiper {
	&-slide {
		width: 100%;
		height: 100%;
		flex-shrink: 0;
		position: relative;
		transition-property: transform;
	}
	&-button {
		&-lock {
			display: none;
		}
	}
	&-wrapper {
		z-index: 1;
		width: 100%;
		height: 100%;
		display: flex;
		position: relative;
		transition-property: transform;
	}
	&-container {
		z-index: 1;
		padding: 0px;
		margin: 0px auto;
		list-style: none;
		overflow: hidden;
		position: relative;

		&-no-flexbox {
			.swiper-slide {
				float: left;
			}
		}
	}
	&-invisible {
		&-blank {
			&-slide {
				visibility: hidden;
			}
		}
	}
	&-pagination {
		font: 0/0 serif;
		text-align: center;

		&-lock {
			display: none;
		}
		&-bullet {
			color: $c3;
			margin: 0px;
			padding: 4px;
			cursor: pointer;
			vertical-align: top;
			display: inline-block;
			transition: all .2s ease-out-sine;

			@mixin screen_to, $mobile {
				padding: 4px;
			}

			&:after {
				width: 6px;
				height: 6px;
				margin: 0px;
				content: '';
				display: block;
				border-radius: 50%;
				background: currentColor;

				@mixin screen_to, $mobile {
					width: 4px;
					height: 4px;
				}
			}

			& + & {
				margin-left: 6px;

				@mixin screen_to, $mobile {
					margin-left: 0px;
				}
			}
			&-active {
				color: #000;
			}
		}
	}
}

.swiper-container-vertical>.swiper-wrapper {
	flex-direction: column;
}
.swiper-container-android .swiper-slide,
.swiper-wrapper {
	transform: translate3d(0px, 0, 0);
}
.swiper-container-multirow>.swiper-wrapper {
	flex-wrap: wrap;
}
.swiper-container-free-mode>.swiper-wrapper {
	margin: 0px auto;
	transition-timing-function: ease-out;
}
/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
	height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
	align-items: flex-start;
	transition-property: transform, height;
}
/* IE10 Windows Phone 8 Fixes */
.swiper-container-wp8-horizontal,
.swiper-container-wp8-horizontal>.swiper-wrapper {
	-ms-touch-action: pan-y;
	touch-action: pan-y;
}
.swiper-container-wp8-vertical,
.swiper-container-wp8-vertical>.swiper-wrapper {
	-ms-touch-action: pan-x;
	touch-action: pan-x;
}
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
	transition-timing-function: ease-out;
}
.swiper-container-fade .swiper-slide {
	pointer-events: none;
	transition-property: opacity;
}
.swiper-container-fade .swiper-slide .swiper-slide {
	pointer-events: none;
}
.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
	pointer-events: auto;
}