.bayan {
	max-width: 988px;
	margin: 60px auto;
	padding: 0px 20px;
	
	@mixin screen_to, $mobile {
		margin: 40px auto;
		padding: 0px 10px;
		max-width: $mwmbl;
	}

	&_item {
		&_btn {
			top: 50%;
			color: $c3;
			right: 20px;
			width: 30px;
			height: 30px;
			margin-top: -15px;
			position: absolute;
			transition: color .2s ease-out-sine;

			@mixin screen_to, $mobile {
				left: 20px;
				width: 16px;
				margin: 0px;
				height: 16px;
				margin-top: -8px;
			}

			&:after,
			&:before {
				top: 50%;
				left: 50%;
				content: '';
				display: block;
				position: absolute;
				background: currentColor;
				transition:
					opacity .4s ease-out-sine,
					transform .4s ease-out-sine;
			}
			&:after {
				width: 2px;
				height: 30px;
				margin: -15px 0px 0px -1px;

				@mixin screen_to, $mobile {
					height: 16px;
					margin-top: -8px;
				}
			}
			&:before {
				width: 30px;
				height: 2px;
				margin: -1px 0px 0px -15px;

				@mixin screen_to, $mobile {
					width: 16px;
					margin-left: -8px;
				}
			}
		}
		&_head {
			font: 0/0 serif;
			cursor: pointer;
			background: $c1;
			position: relative;
			transform: translateZ(0);
			padding: 5px 70px 24px 40px;

			@mixin screen_to, $mobile {
				padding: 5px 20px 25px;
			}

			&:after {
				top: 0px;
				left: 0px;
				width: 0px;
				z-index: -1;
				content: '';
				height: 100%;
				position: absolute;
				background: $chover;
				transition: all .4s ease-out-sine;
			}
		}
		&_type {
			color: $c3;
			display: block;
			font: _font(14, 18, $f1);
			transition: all .2s ease-out-sine;

			&:before {
				font-size: 24px;
				content: '\2022';
				line-height: 14px;
				margin-right: 8px;
				vertical-align: top;
				display: inline-block;
			}
		}
		&_name {
			color: #fff;
			display: block;
			margin-top: 10px;
			font: _font(24, 28, $f1);
			transition: all .2s ease-out-sine;

			@mixin screen_to, $mobile {
				font: _font(18, 22, $f1);
				margin: 11px 0px 0px 26px;
			}

			&:first-child {
				margin-top: 19px;

				@mixin screen_to, $mobile {
					margin-top: 20px;
				}
			}
		}
		&_body {
			display: none;
			background: #fff;
			padding: 24px 70px 24px 40px;
			border-left: 2px solid $chover;
			border-right: 2px solid $chover;
			border-bottom: 2px solid $chover;

			@mixin screen_to, $mobile {
				padding: 15px 20px 16px;
			}
		}

		& + & {
			margin-top: 2px;
		}
		&:hover,
		&._active {
			^&_btn,
			^&_type,
			^&_name {
				color: $chover;
			}
		}
		&._active {
			^&_btn,
			^&_type,
			^&_name {
				color: #000;
			}
			^&_btn {
				&:after {
					opacity: 0;
					transform: scale(0);
				}
			}
			^&_head {
				&:after {
					width: 100%;
				}
			}
		}
	}

	&:last-child {
		margin-bottom: 0px;
	}
	&:first-child {
		margin-top: 0px;
	}
}