.faq {
	max-width: 988px;
	margin: 0px auto;
	padding: 0px 20px;

	@mixin screen_to, $mobile {
		padding: 0px 10px;
		max-width: $mwmbl;
	}

	&_head {
		margin-bottom: 20px;
	}
	&_foot {
		font: 0/0 serif;
		margin-top: 58px;
		text-align: center;

		@mixin screen_to, $mobile {
			margin-top: 37px;
		}

		&_lbl {
			color: $c4;
			display: block;
			font: 16px/1 $f2i;

			@mixin screen_to, $mobile {
				font-size: 14px;
			}
		}
		&_btn {
			margin-top: 17px;

			@mixin screen_to, $mobile {
				margin-top: 18px;
			}
		}
	}
	&_item {
		cursor: pointer;
		overflow: hidden;
		padding: 0px 40px;
		background: #fff;
		position: relative;

		@mixin screen_to, $mobile {
			min-height: auto;
			padding: 0px 15px;
		}

		&:after {
			left: 0px;
			width: 100%;
			height: 1px;
			z-index: 10;
			content: '';
			display: block;
			background: #fff;
			position: absolute;
			box-shadow: 0px 0px 30px 30px #fff;
		}

		&_tag {
			height: 30px;
			color: #fff;
			min-width: 170px;
			padding: 0px 15px;
			text-align: center;
			font: 14px/30px $f1;
			vertical-align: top;
			display: inline-block;

			@mixin screen_to, $mobile {
				min-width: auto;
				font-size: 12px;
			}

			&._1 {
				background: #f69dde;
			}
			&._2 {
				background: #98e1fb;
			}
		}
		&_btn {
			top: 50%;
			right: 0px;
			width: 38px;
			z-index: 15;
			height: 38px;
			background: $c1;
			margin-top: -19px;
			position: absolute;
			border-radius: 50%;

			@mixin screen_to, $mobile {
				width: 30px;
				height: 30px;
				margin-top: -15px;
			}

			&:after,
			&:before {
				top: 50%;
				left: 50%;
				content: '';
				display: block;
				background: #fff;
				position: absolute;
				transition: all .4s ease-out-sine;
			}
			&:after {
				width: 19px;
				height: 1px;
				margin-left: -9px;

				@mixin screen_to, $mobile {
					width: 17px;
					margin-left: -8px;
				}
			}
			&:before {
				width: 1px;
				height: 19px;
				margin-top: -9px;

				@mixin screen_to, $mobile {
					height: 17px;
					margin-top: -8px;
				}
			}
		}
		&_name {
			color: $c1;
			z-index: 15;
			display: block;
			max-width: 754px;
			margin-top: 13px;
			position: relative;
			font: _font(22, 26, $f1b);

			@mixin screen_to, $mobile {
				margin-top: 7px;
				font: 18px/1 $f1b;
			}
		}
		&_head {
			position: relative;
			padding: 0px 78px 35px 0px;
			
			@mixin screen_to, $mobile {
				padding: 0px 45px 17px 0px;
			}
		}
		&_body {
			display: none;
			overflow: hidden;
			max-width: 754px;
			margin-top: -16px;
			padding-bottom: calc(40px - $p_bl);

			@mixin screen_to, $mobile {
				margin-top: -5px;
				padding-bottom: calc(20px - $p_bl_m);
			}
		}

		& + & {
			margin-top: 10px;
		}
		&-open {
			cursor: auto;
			box-shadow: none;

			^&_btn {
				&:after,
				&:before {
					transform: rotate(45deg);
				}
			}
			^&_body {
			}
		}
		&._hide {
			display: none;
		}
	}
	&_select {
		max-width: 280px;
		border-color: #fff;
		box-shadow: 0px 6px 18px rgba(#000, .05);

		@mixin screen_to, $mobile {
			max-width: 300px;
		}

		.sod {
			&_list {
				&_wrapper {
					border-color: #fff;
					box-shadow: 0px 6px 18px rgba(#000, .05);
				}
			}
			&_select {
				&.above {
					.sod {
						&_list {
							&_wrapper {
								box-shadow: 0px -6px 18px rgba(#000, .05);
							}
						}
					}
				}
			}
		}
	}
}