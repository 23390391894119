.tform {
	max-width: 988px;
	padding: 0px 20px;
	margin: 40px auto 0px;
	
	@mixin screen_to, $mobile {
		margin-top: 10px;
		padding: 0px 10px;
		max-width: $mwmbl;
	}

	&_body {
		font: 0/0 serif;
		background: #fff;
		border-radius: 20px;
		box-shadow: 0px 6px 18px rgba(#000, .05);
	}
	&_menu {
		user-select: none;

		&_link {
			width: 50%;
			color: $c4;
			cursor: pointer;
			position: relative;
			text-align: center;
			vertical-align: top;
			display: inline-block;
			background: #efefef;
			font: _font(22, 60, $f1b);
			transition: all .2s ease-out-sine;

			@mixin screen_to, $mobile {
				font: _font(14, 48, $f1b);
			}

			&:hover {
				color: #000;
			}
			&._active {
				color: #000;
				cursor: default;
				background: #fff;
			}
			&:last-child {
				border-radius: 0px 20px 0px 0px;
				box-shadow: inset 10px 0px 0px #fff;
				
				@mixin screen_to, $mobile {
					box-shadow: none;
					border-radius: 0px 10px 0px 0px;
				}
			}
			&:first-child {
				border-radius: 20px 0px 0px 0px;
				box-shadow: inset -10px 0px 0px #fff;
				
				@mixin screen_to, $mobile {
					box-shadow: none;
					border-radius: 10px 0px 0px 0px;
				}
			}
		}
	}
	&_item {
		display: none;
		padding: 60px 40px;
		
		@mixin screen_to, $mobile {
			padding: 27px 15px 30px;
		}

		&._active {
			display: block;
		}
	}
	&_before {
		max-width: 908px;
		padding: 0px 20px;
		margin: 0px auto 34px;

		@mixin screen_to, $mobile {
			padding: 0px 5px;
			max-width: $mwmbl;
			margin-bottom: 16px;
		}

		&_box {
			max-width: 800px;

			&:last-child,
			& > :last-child {
				margin-bottom: 0px;
			}
			&:first-child,
			& > :first-child {
				margin-top: 0px;
			}
		}
	}

	&:first-child {
		@mixin screen_to, $mobile {
			margin-top: 35px;
		}
	}
}