ol,
ul {
	margin: 0px;
	padding: 0px;
	display: block;
	list-style: none;
	counter-reset: l1;

	ol,
	ul {
		padding-left: 20px;
	}
}
li {
	padding: 0px;

	@if $li_mn == 0px {
		margin: 0px;
	} @else {
		margin: calc($li_mn - ($li_cl + $li_bl)) 0px 0px;
	}

	@mixin screen_to, $mobile {
		@if $li_mn_m != 0px {
			margin-top: calc($li_mn_m - ($li_cl_m + $li_bl_m));
		}
	}

	&:before {
		vertical-align: top;
		display: inline-block;
	}
	&:first-child {
		margin-top: 0px;
	}

	@if $font_debug == 1 {
		background-color: #48bec4;
	}
}
ol {
	li {
		&:before {
			min-width: 14px;
			margin-right: 4px;
			font-size: 1.143em;
			counter-increment: l1;
			content: counter(l1)'.';
		}

		ol,
		ul {
			counter-reset: l2;

			li {
				&:before {
					counter-increment: l2;
					content: counter(l1)'.'counter(l2);
				}
			}
		}
	}
}
ul {
	li {
		&:before {
			content: '\2022';
			margin-right: 8px;
			vertical-align: top;
			display: inline-block;
			font-size: em(16, 18);
		}
	}
}