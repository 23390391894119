.checkbox {
	width: 30px;
	height: 30px;
	cursor: pointer;
	color: #00a651;
	text-align: center;
	background: #fff;
	position: relative;
	border: 1px solid $c3;
	display: inline-block;
	vertical-align: middle;
	transition: all .4s ease-out-sine;

	&:after {
		opacity: 0;
		display: block;
		content: $ico_done;
		transform: scale(0);
		font: _font(14, 30, $ico);
		transition: all .2s ease-out-sine;
	}

	&._error {
		border-color: $cerror;
	}
	&._checked {
		&:after {
			opacity: 1;
			transform: none;
		}
	}
	&:hover
	label:hover & {
		border-color: $c1;
	}
}