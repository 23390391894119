.lfooter {
	font-size: 0px;
	font: 0/0 serif;
	margin-top: 40px;
	text-align: center;
	padding: 0px 20px 37px;

	@mixin screen_to, $mobile {
		margin-top: 30px;
		padding: 0px 15px 27px;
	}

	&_copyright {
		color: $c4;
		display: block;
		margin-top: 28px;
		font: 16px/1 $f1;
	}
}