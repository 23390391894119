.labout {
	width: 100%;
	max-width: 384px;
	transition:
		opacity .2s ease-out-sine,
		visibility .2s ease-out-sine;

	@mixin screen_to, $mobile {
		max-width: none;
		padding: 0px 5px;
		margin: 24px auto 0px;
	}

	&_title {
		margin: -6px 0px 31px;
		font: _font(36, 44, $f1b);

		@mixin screen_to, $mobile {
			font: 24px/1 $f1b;
		}
	}

	&:hover {
		mark {
			@mixin screen_from, $mobile {
				background-position: -100% 0% !important;
			}
		}
	}
	.is_clean & {
		opacity: 0;
		visibility: hidden;
	}
}