.hidden {
	width: 0px;
	height: 0px;
	right: 150%;
	bottom: 150%;
	max-width: 0px;
	max-height: 0px;
	position: fixed;
	overflow: hidden;
	visibility: hidden;
}
.video {
	&_box {
		position: relative;
		background: #dbdbdb;
		padding-bottom: 56.25%;
	}
}

._hide4dsk {
	@mixin screen_from, $mobile {
		display: none;
	}
}
._hide4mbl {
	@mixin screen_to, $mobile {
		display: none;
	}
}

._overflow {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	
	&:last-child {
		margin-bottom: 0px;
	}
	&:first-child {
		margin-top: 0px;
	}
}
._clearfix {
	&:after,
	&:before {
		clear: both;
		content: '';
		display: table;
	}
}
._no_scrolling {
	overflow: hidden;
}

._flft {float: left;}
._frgt {float: right;}

._alft {text-align: left;}
._argt {text-align: right;}
._actr {text-align: center;}

._vtop {vertical-align: top;}
._vmid {vertical-align: middle;}
._vbot {vertical-align: bottom;}

._ttup {text-transform: uppercase;}
._ttlw {text-transform: lowercase;}

._txt_c1 {color: $cb1v2;}
._txt_c2 {color: $cb2v2;}
._txt_c3 {color: $cb3v2;}
._txt_c4 {color: $cb4v2;}

._m_new_line {
	@mixin screen_to, $mobile {
		display: block;
	}
}

._delay_100 {transition-delay: .1s;}
._delay_200 {transition-delay: .2s;}
._delay_300 {transition-delay: .3s;}
._delay_400 {transition-delay: .4s;}
._delay_500 {transition-delay: .5s;}
._delay_600 {transition-delay: .6s;}
._delay_700 {transition-delay: .7s;}
._delay_800 {transition-delay: .8s;}