.ufile {
	max-width: 988px;
	margin: 60px auto;
	padding: 0px 20px;
	text-align: center;

	@mixin screen_to, $mobile {
		margin: 40px auto;
		padding: 0px 25px;
		max-width: $mwmbl;
	}

	&:before {
		height: 1px;
		content: '';
		display: block;
		background: $c3;
		margin-bottom: 30px;

		@mixin screen_to, $mobile {
			margin: 0px -15px 20px;
		}
	}

	&_item {
		border: none;
		vertical-align: top;
		padding: 5px 0px 0px;
		display: inline-block;
		font: _font(18, 24, $f1);

		@mixin screen_to, $mobile {
			font-size: 14px;
			text-align: left;
			padding: 5px 0px 0px 40px;
		}

		&:before {
			font: 30px/1 $ico;
			vertical-align: top;
			display: inline-block;
			content: $ico_download;
			margin: -8px 30px 0px 0px;

			@mixin screen_to, $mobile {
				top: 50%;
				left: 0px;
				display: block;
				font-size: 20px;
				position: absolute;
				margin: -10px 0px 0px 0px;
			}
		}

		&_sep {
			margin: 0px 4px;
			vertical-align: top;
			display: inline-block;
		}
		&_type {
			color: $c4;
			text-transform: uppercase;
		}
		&_name {
			transition: all .2s ease-out-sine;
			border-bottom: 1px solid transparent;
		}

		&:hover {
			^&_name {
				border-color: currentColor;
			}
		}
	}

	&:last-child {
		margin-bottom: 0px;
	}
	&:first-child {
		margin-top: 0px;
	}
}