@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}
@keyframes pulse {
	100% {
		transform: scale(1.06);
	}
}
@keyframes fade-in {
	100% {
		opacity: 0;
	}
}
@keyframes fade-out {
	100% {
		opacity: 0;
	}
}
@keyframes ball-beat {
	50% {
		transform: scale(1.2);
	}
}
@keyframes gradient {
	0% {
		background-position: 50% 0%
	}
	50% {
		background-position: 50% 100%
	}
	100% {
		background-position: 50% 0%
	}
}