.lnews {
	display: flex;
	flex-flow: wrap;
	margin-top: 1px;
	text-align: left;
	align-items: flex-start;
	justify-content: space-between;

	&_item {
		border: none;
		color: #000;
		display: block;
		margin: 37px 0px 0px;
		padding: 0px 0px 22px;
		width: calc(50% - 59px);

		@mixin screen_to, $mobile {
			width: 100%;
			padding: 0px;
			margin: 0px auto;
			max-width: 320px;
		}

		&_date {
			color: $c4;
			display: block;
			font: 14px/1 $f1;
			text-align: right;
			margin-bottom: 9px;
			
			@mixin screen_to, $mobile {
				font-size: 12px;
				text-align: left;
				margin-bottom: 14px;
			}
		}
		&_name {
			vertical-align: top;
			background: $gSelection;
			font: _font(30, 36, $f1);
			transition: all .4s ease-in-out-sine;
			background-size: 200%;

			@mixin screen_to, $mobile {
				background: none;
				font: 24px/1 $f1;
				vertical-align: top;
				display: inline-block;
			}

			&._bold {
				font-family: $f1b;
			}
			&:hover {
				color: #000;
				background-position: -100% 0%;
			}
		}
		&_text {
			margin-top: 16px;

			@mixin screen_to, $mobile {
				margin-top: 11px;
			}
		}
		&_link {
			right: 0px;
			bottom: 0px;
			position: absolute;

			@mixin screen_to, $mobile {
				display: none;
			}
		}

		& + & {
			@mixin screen_to, $mobile {
				display: none;
			}
		}
		&:hover {
			color: #000;

			^&_name {
				background-position: -100% 0%;
			}
		}
		&:nth-child(-n + 2) {
			margin-top: 0px;
		}
	}
}