.uloader {
	top: 50%;
	left: 50%;
	position: absolute;

	&_itm,
	&:after,
	&:before {
		top: -30px;
		opacity: 0;
		content: '';
		left: -30px;
		margin: 0px;
		width: 60px;
		height: 60px;
		display: block;
		background: #fff;
		border-radius: 50%;
		position: absolute;
		animation: ball-scale-multiple 1s 0s linear infinite both;
	}
	&_itm {
		animation-delay: -0.2s;
	}
	&:after {
		animation-delay: -0.4s;
	}
}

@keyframes ball-scale-multiple {
	0% {
		opacity: 0;
		transform: scale(0);
	}
	5% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: scale(1);
		box-shadow: 0px 0px 30px rgba(#000, .8);
	}
}